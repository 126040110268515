import React, { FC } from 'react';
import { IconInterface } from './index';

const SchoolabyLogo: FC<IconInterface> = ({ name, style = {}, width = '93px', height = '10px', viewBox = '0 0 93 10' }) => (
  <svg aria-label={name} width={width} height={height} style={style} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.75906 4.2715C6.26154 4.09381 5.64321 3.93745 4.91826 3.80241C4.35678 3.6958 3.95167 3.58919 3.70291 3.4968C3.45415 3.4044 3.33333 3.25515 3.33333 3.06325C3.33333 2.66524 3.74555 2.45913 4.57711 2.45913C5.41577 2.45913 6.30419 2.69367 7.24235 3.16986L8.20895 0.831556C7.72565 0.561478 7.17128 0.348259 6.53873 0.21322C5.90618 0.0710733 5.25941 0 4.60554 0C3.65316 0 2.83582 0.142147 2.16773 0.426439C1.49254 0.710732 0.995024 1.09453 0.653873 1.59204C0.319829 2.08244 0.149254 2.62971 0.149254 3.23383C0.149254 3.92324 0.334044 4.47761 0.696517 4.88273C1.05899 5.28784 1.48543 5.57924 1.98294 5.75693C2.47335 5.93461 3.09168 6.09808 3.82374 6.25444C4.39943 6.37526 4.81165 6.48187 5.06041 6.58137C5.30916 6.68088 5.4371 6.83013 5.4371 7.02914C5.4371 7.19971 5.3447 7.33475 5.1528 7.42004C4.96091 7.50532 4.64108 7.54797 4.19332 7.54797C3.67448 7.54797 3.13433 7.46979 2.56574 7.30632C2.00426 7.14996 1.49254 6.93674 1.03767 6.67377L0 9.01918C0.483297 9.31769 1.10163 9.55934 1.8479 9.73702C2.60128 9.91471 3.36887 9.99999 4.16489 9.99999C5.12437 9.99999 5.93461 9.85785 6.60269 9.56645C7.27078 9.28215 7.7754 8.89125 8.10945 8.40796C8.44349 7.91755 8.61407 7.37739 8.61407 6.77327C8.61407 6.08386 8.42928 5.5366 8.05969 5.13148C7.69722 4.74058 7.26368 4.44918 6.75906 4.2715Z"
      fill="black"
    />
    <path
      d="M14.6837 2.61543C15.4868 2.61543 16.1833 2.99212 16.7732 3.73839L18.8059 1.91892C18.3297 1.30058 17.7327 0.824391 17.0006 0.497454C16.2757 0.170518 15.4441 0.00704956 14.5131 0.00704956C13.5038 0.00704956 12.5941 0.220269 11.7839 0.639601C10.9736 1.06604 10.3411 1.65595 9.87911 2.41643C9.41713 3.17691 9.1897 4.0369 9.1897 5.00349C9.1897 5.97009 9.41713 6.83007 9.87911 7.59055C10.3411 8.35104 10.9736 8.94094 11.7839 9.36738C12.5941 9.79382 13.5038 9.99994 14.5131 9.99994C15.4441 9.99994 16.2686 9.83647 17.0006 9.50953C17.7256 9.18259 18.3297 8.7064 18.8059 8.08807L16.7732 6.2686C16.1833 7.01486 15.4868 7.39155 14.6837 7.39155C14.0085 7.39155 13.4683 7.17833 13.0561 6.74479C12.6439 6.31124 12.4448 5.72844 12.4448 5.00349C12.4448 4.27855 12.651 3.69575 13.0561 3.2622C13.4683 2.82865 14.0085 2.61543 14.6837 2.61543Z"
      fill="black"
    />
    <path
      d="M25.6788 3.61053H22.7008V0.220337H19.4812V9.78678H22.7008V6.26155H25.6788V9.78678H28.9055V0.220337H25.6788V3.61053Z"
      fill="black"
    />
    <path
      d="M38.2444 0.646708C37.4271 0.220269 36.5031 0.00704956 35.4726 0.00704956C34.442 0.00704956 33.5181 0.220269 32.7007 0.646708C31.8834 1.07315 31.2366 1.67016 30.7746 2.43064C30.3127 3.19113 30.0781 4.05111 30.0781 5.00349C30.0781 5.95587 30.3127 6.81586 30.7746 7.57634C31.2366 8.33682 31.8834 8.93384 32.7007 9.36028C33.5181 9.78671 34.442 9.99994 35.4726 9.99994C36.5031 9.99994 37.4271 9.78671 38.2444 9.36028C39.0618 8.93384 39.7085 8.33682 40.1705 7.57634C40.6325 6.81586 40.867 5.95587 40.867 5.00349C40.867 4.05111 40.6325 3.19113 40.1705 2.43064C39.7085 1.67016 39.0689 1.07315 38.2444 0.646708Z"
      fill="black"
    />
    <path
      d="M49.8294 0.646708C49.0121 0.220269 48.0881 0.00704956 47.0575 0.00704956C46.027 0.00704956 45.103 0.220269 44.2857 0.646708C43.4683 1.07315 42.8216 1.67016 42.3596 2.43064C41.8976 3.19113 41.6631 4.05111 41.6631 5.00349C41.6631 5.95587 41.8976 6.81586 42.3596 7.57634C42.8216 8.33682 43.4683 8.93384 44.2857 9.36028C45.103 9.78671 46.027 9.99994 47.0575 9.99994C48.0881 9.99994 49.0121 9.78671 49.8294 9.36028C50.6467 8.93384 51.2935 8.33682 51.7555 7.57634C52.2174 6.81586 52.452 5.95587 52.452 5.00349C52.452 4.05111 52.2174 3.19113 51.7555 2.43064C51.2864 1.67016 50.6467 1.07315 49.8294 0.646708ZM48.9125 6.2686C48.7207 6.63107 48.4648 6.90825 48.1379 7.10015C47.8109 7.29916 47.4555 7.39155 47.0504 7.39155C46.6524 7.39155 46.2899 7.29205 45.963 7.10015C45.6361 6.90115 45.3802 6.62396 45.1883 6.2686C44.9964 5.90612 44.904 5.48679 44.904 5.00349C44.904 4.5202 44.9964 4.10086 45.1883 3.73839C45.3802 3.37592 45.6361 3.09873 45.963 2.90683C46.2828 2.70783 46.6453 2.61543 47.0504 2.61543C47.4484 2.61543 47.8109 2.71494 48.1379 2.90683C48.4577 3.10584 48.7207 3.38302 48.9125 3.73839C49.1044 4.10086 49.1968 4.5202 49.1968 5.00349C49.1968 5.48679 49.1044 5.90612 48.9125 6.2686Z"
      fill="black"
    />
    <path d="M56.3398 0.220337H53.113V9.78678H60.6539V7.28501H56.3398V0.220337Z" fill="black" />
    <path
      d="M65.1955 0.220337L61.0164 9.78678H64.2928L64.9325 8.11656H68.5643L69.204 9.78678H72.5373L68.3582 0.220337H65.1955Z"
      fill="black"
    />
    <path
      d="M80.7036 4.78323C81.1372 4.56291 81.4712 4.27861 81.6915 3.92325C81.9119 3.56788 82.0256 3.16987 82.0256 2.72211C82.0256 1.96874 81.6986 1.35751 81.0519 0.902638C80.398 0.44777 79.4385 0.220337 78.1663 0.220337H73.0562V9.77967H78.4364C79.7441 9.77967 80.7463 9.55224 81.4428 9.09027C82.1322 8.62829 82.4805 7.98152 82.4805 7.14286C82.4805 6.58138 82.3241 6.09809 82.0185 5.69297C81.7058 5.30207 81.2722 4.99645 80.7036 4.78323ZM76.226 2.48046H77.7257C78.4293 2.48046 78.7775 2.715 78.7775 3.19119C78.7775 3.66738 78.4293 3.90193 77.7257 3.90193H76.226V2.48046ZM78.1663 7.53376H76.226V6.03412H78.1663C78.877 6.03412 79.2324 6.28288 79.2324 6.7875C79.2324 7.2779 78.877 7.53376 78.1663 7.53376Z"
      fill="black"
    />
    <path
      d="M89.282 0.220337L87.3132 3.52524L85.3587 0.220337H81.9614L85.5648 6.27577V9.78678H88.7916V6.31131L92.395 0.220337H89.282Z"
      fill="black"
    />
  </svg>
);

export default SchoolabyLogo;
