import React, { FC } from 'react';
import './language-selector.scss';
import { Dropdown, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { DownOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import Icon from '../../icons';

const LanguageSelector: FC = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng).catch(() => console.error('Could not change language'));
  };

  const getFlagByLanguage = (language: string) => {
    if (language === 'en') {
      return <Icon name={'english-flag'} />;
    } else if (language === 'et') {
      return <Icon name={'estonian-flag'} />;
    } else if (language === 'fi') {
      return <Icon name={'finnish-flag'} />;
    } else if (language === 'uk') {
      return <Icon name={'ukrainian-flag'} />;
    } else {
      return <QuestionCircleOutlined />;
    }
  };

  const menu = (
    <Menu>
      <Menu.Item key="0" onClick={() => changeLanguage('en')}>
        <Icon name={'english-flag'} />
      </Menu.Item>
      <Menu.Item key="1" onClick={() => changeLanguage('et')}>
        <Icon name={'estonian-flag'} />
      </Menu.Item>
      <Menu.Item key="2" onClick={() => changeLanguage('fi')}>
        <Icon name={'finnish-flag'} />
      </Menu.Item>
      <Menu.Item key="3" onClick={() => changeLanguage('uk')}>
        <Icon name={'ukrainian-flag'} />
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown className={'language-selector'} overlay={menu} trigger={['click']}>
      <span className={'dropdown-value'} data-testid={'language-selector'} onClick={e => e.preventDefault()}>
        <DownOutlined /> {getFlagByLanguage(i18n.language)}
      </span>
    </Dropdown>
  );
};

export default LanguageSelector;
