import axios, { AxiosResponse } from 'axios';
import { Question } from '../model/question.model';

export const createQuestion = (entity: Question): Promise<AxiosResponse<Question>> => {
  return axios.post<Question>(`/api/questions`, entity);
};

export const updateQuestion = (entity: Question): Promise<AxiosResponse<Question>> => {
  return axios.put<Question>(`/api/questions`, entity);
};

export const deleteQuestion = (id: number): Promise<AxiosResponse<void>> => {
  return axios.delete<void>(`/api/questions/${id}`);
};
