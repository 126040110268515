export const APPLICATION_NAME = 'Quizzeria';

export type Constants = {
  SCHOOLABY_URL: string;
};

let config: Constants;

const local = {
  SCHOOLABY_URL: process.env.REACT_APP_HTTPS ? 'https://localhost:8000' : 'http://localhost:8000',
};

const dev = {
  SCHOOLABY_URL: 'https://test-schoolaby-test.ml',
};

const prod = {
  SCHOOLABY_URL: 'https://app.schoolaby.com',
};

const hostname = window && window.location && window.location.hostname;

if (hostname === 'quizzeria.schoolaby.com') {
  config = prod;
} else if (hostname === 'quizzeria.test-schoolaby-test.ml') {
  config = dev;
} else {
  config = local;
}

export default {
  ...config,
};
