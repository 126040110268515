import React, { FC, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';

interface InfoModalProps {
  buttonClassName?: string;
  modalContent: JSX.Element | JSX.Element[];
}

const InfoModal: FC<InfoModalProps> = ({ buttonClassName = '', modalContent = '' }) => {
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <>
      <InfoCircleOutlined className={buttonClassName} onClick={() => setVisible(true)} />
      <Modal visible={visible} onCancel={() => setVisible(false)} footer={null}>
        {modalContent}
      </Modal>
    </>
  );
};

export default InfoModal;
