import React, { FC } from 'react';
import { IconInterface } from './index';

const SubjectIcon: FC<IconInterface> = ({
  name,
  style = {},
  width = '18px',
  height = '18px',
  viewBox = '0 0 18 18',
  className = '',
  fill = 'none',
  stroke = '#6B33F9',
}) => (
  <svg
    aria-label={name}
    className={className}
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 4.68975V14.4398M9 4.68975C8.124 4.10775 6.9345 3.75 5.625 3.75C4.3155 3.75 3.126 4.10775 2.25 4.68975V14.4398C3.126 13.8578 4.3155 13.5 5.625 13.5C6.9345 13.5 8.124 13.8578 9 14.4398M9 4.68975C9.876 4.10775 11.0655 3.75 12.375 3.75C13.6852 3.75 14.874 4.10775 15.75 4.68975V14.4398C14.874 13.8578 13.6852 13.5 12.375 13.5C11.0655 13.5 9.876 13.8578 9 14.4398"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SubjectIcon;
