import React, { FC } from 'react';
import BaseQuestionView from './base-question-view';
import { Answer } from '../../../model/answer.model';
import { Question } from '../../../model/question.model';
import { getAnswerColorClass, isTextBasedQuestion, textAnswerCorrect } from '../../../shared/util/quiz-util';
import { Choice } from '../../../model/choice.model';
import { Checkbox, Divider, Input, Radio, Space } from 'antd';
import './question-detail-view.scss';
import { useTranslation } from 'react-i18next';
import { MULTIPLE_CHOICE_CHECKBOX, MULTIPLE_CHOICE_RADIO } from '../../../config/constants';
import { CORRECT, INCORRECT, PARTIALLY_CORRECT } from '../../../shared/constants';
import MathJax from 'react-mathjax-preview';

interface IProps {
  sequenceNumber: number;
  onEdit: () => void;
  answer?: Answer;
  question: Question;
  editable?: boolean;
  isPreview?: boolean;
}

const QuestionDetailView: FC<IProps> = ({ sequenceNumber, onEdit, answer, question, editable, isPreview }: IProps) => {
  const { t } = useTranslation();
  const isSubmittedView = !editable && !isPreview;

  const answerColorClass: string = isSubmittedView ? getAnswerColorClass(answer, question) : '';

  const getCorrectChoices = (): Choice[] | undefined => {
    return question?.choices?.filter(choice => choice.correct);
  };

  const choiceChosen = (choice: Choice): boolean => {
    return answer?.choices?.some(c => c.id === choice.id) || false;
  };

  const anyChoiceCorrect = (): boolean => {
    return answer?.choices?.some(c => c.correct) || false;
  };

  const getChoiceBackground = (choice: Choice): typeof CORRECT | typeof INCORRECT | typeof PARTIALLY_CORRECT | '' => {
    if (choiceChosen(choice) && choice.correct) {
      return CORRECT;
    } else if (choiceChosen(choice) && !choice.correct) {
      return INCORRECT;
    } else if (anyChoiceCorrect() && !choiceChosen(choice) && choice.correct) {
      return PARTIALLY_CORRECT;
    }

    return '';
  };

  const getTextAnswerBackground = (textAnswer: string): typeof CORRECT | typeof INCORRECT => {
    if (textAnswerCorrect(textAnswer, question)) {
      return CORRECT;
    }
    return INCORRECT;
  };

  const renderText = (): JSX.Element => (
    <Input
      className={`choice-element ${isSubmittedView ? getTextAnswerBackground(answer?.textAnswer || '') : ''}`}
      disabled={!isPreview}
      value={isSubmittedView ? answer?.textAnswer || '-' : ''}
    />
  );

  const renderCheckBoxOrRadioByQuestionType = (choice: Choice, checked?: boolean): JSX.Element => {
    if (question.questionType === MULTIPLE_CHOICE_CHECKBOX) {
      return <Checkbox disabled={!isPreview} checked={choiceChosen(choice) || checked} />;
    } else if (question.questionType === MULTIPLE_CHOICE_RADIO) {
      return <Radio disabled={!isPreview} checked={choiceChosen(choice) || checked} />;
    }

    return <></>;
  };

  const renderChoiceLetter = (choice: Choice, index: number): JSX.Element => (
    <div className={`font-medium choice-index-letter ${choice.correct ? 'choice-index-letter-correct' : ''}`}>
      {String.fromCharCode(index + 65)}
    </div>
  );

  const renderChoice = (choice: Choice, index: number, renderLeftSideComponent: JSX.Element, withColor?: boolean): JSX.Element => (
    <div className={`choice-element ${!editable && withColor ? getChoiceBackground(choice) : ''}`} key={index}>
      <Space align={'center'}>
        {renderLeftSideComponent}
        <div className={'choice-string-col text-sm font-normal'}>
          <MathJax math={choice.choiceString} />
        </div>
      </Space>
    </div>
  );

  const renderEditableChoice = (choice: Choice, index: number): JSX.Element =>
    renderChoice(choice, index, renderChoiceLetter(choice, index));

  const renderStudentChoice = (choice: Choice, index: number): JSX.Element =>
    renderChoice(choice, index, renderCheckBoxOrRadioByQuestionType(choice), true);

  const renderCorrectChoice = (choice: Choice, index: number): JSX.Element =>
    renderChoice(choice, index, renderCheckBoxOrRadioByQuestionType(choice, true), false);

  const renderChoices = (): JSX.Element => (
    <div>
      {isTextBasedQuestion(question) && isSubmittedView
        ? renderText()
        : question.choices?.map(!isSubmittedView ? renderEditableChoice : renderStudentChoice)}
    </div>
  );

  const renderCorrectChoices = (): JSX.Element => {
    return !isPreview ? (
      <>
        <Divider orientation={'left'} className={'divider-color'}>
          {t('quiz.submitted.correctAnswers')}
        </Divider>
        {getCorrectChoices()?.map((choice, i) => renderCorrectChoice(choice, i))}
      </>
    ) : (
      <></>
    );
  };

  return (
    <BaseQuestionView
      dataTestid={'question-detail-view'}
      className={'question-detail-view'}
      sequenceNumber={sequenceNumber}
      answerColorClass={answerColorClass}
      onEdit={onEdit}
      answer={answer}
      question={question}
      choicesElement={renderChoices()}
      correctChoicesElement={renderCorrectChoices()}
      editable={editable}
      isPreview={isPreview}
    />
  );
};

export default QuestionDetailView;
