import React, { Dispatch, FC, SetStateAction } from 'react';
import { Page, PageRequest } from '../../page';
import { Pagination } from 'antd';

export type IProps = {
  page?: Page<any>;
  pageRequest: PageRequest;
  setPageRequest: Dispatch<SetStateAction<PageRequest>>;
};

export const CustomPagination: FC<IProps> = ({ page, pageRequest, setPageRequest }) => {
  return page && !!page?.totalElements ? (
    <Pagination
      className={'custom-pagination'}
      total={page?.totalElements}
      current={page?.number + 1}
      onChange={value => setPageRequest({ ...pageRequest, page: value })}
      pageSize={pageRequest?.size}
    />
  ) : null;
};
