import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { getAllEducationalAlignments, SUBJECT, SUBJECT_AREA } from '../../api/schoolabyApi';
import { createQuiz, updateQuiz } from '../../api/quizApi';
import { Quiz } from '../../model/quiz.model';
import { EducationalAlignment } from '../../model/educational-alignment.model';
import { useHistory } from 'react-router-dom';
import { useQuizState } from '../../shared/context/quiz-edit-context';
import ImageUpload from '../shared/image-upload';

interface ISaveQuizForm {
  title: string;
  description?: string;
  subjectArea: string;
  subjects: string[];
}

interface ISaveQuizModal {
  visible: boolean;
  onClose: () => void;
  onUpdate?: () => void;
}

const SaveQuizModal: FC<ISaveQuizModal> = ({ visible, onClose, onUpdate }) => {
  const [subjectAreas, setSubjectAreas] = useState<EducationalAlignment[]>([]);
  const [subjects, setSubjects] = useState<EducationalAlignment[]>([]);
  const [uniqueImageName, setUniqueImageName] = useState<string | undefined>();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const history = useHistory();
  const { quiz } = useQuizState();

  const { Option } = Select;

  useEffect(() => {
    setUniqueImageName(quiz?.image);
  }, [quiz]);

  useEffect(() => {
    if (visible) {
      getAllEducationalAlignments({ alignmentType: SUBJECT_AREA })
        .then(resp => setSubjectAreas(resp))
        .catch(() => console.error('Could not fetch educational alignments'));
    }
  }, [visible]);

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values: ISaveQuizForm) => {
        const subjectList: EducationalAlignment[] = values.subjects.map((s: string) => ({
          targetName: s,
          alignmentType: SUBJECT,
        }));
        const quizData: Quiz = {
          ...quiz,
          published: quiz?.published || false,
          title: values.title,
          description: values.description,
          subjectArea: {
            targetName: values.subjectArea,
            alignmentType: SUBJECT_AREA,
          },
          subjects: subjectList,
          image: uniqueImageName,
        };
        quiz?.id
          ? updateQuiz(quizData)
              .then(() => {
                onUpdate && onUpdate();
                onClose();
              })
              .catch(() => console.error('Failed to update quiz'))
          : createQuiz(quizData)
              .then(res => history.push(`/quiz/${res.data.id || 'undefined'}/edit`))
              .catch(() => console.error('Failed to create quiz'));
      })
      .catch(() => console.error('Failed to validate form fields'));
  };

  const getSuggestions = (title: string) => {
    title &&
      getAllEducationalAlignments({
        alignmentType: SUBJECT,
        title,
      }).then(response => setSubjects(response));
  };

  const renderForm = () => {
    return (
      <Form layout="vertical" name="basic" form={form} requiredMark={false}>
        <Form.Item
          label={t('quiz.modal.title')}
          name="title"
          initialValue={quiz?.title || ''}
          rules={[
            {
              required: true,
              message: `${t('quiz.modal.enterTitle')}`,
            },
          ]}
        >
          <Input placeholder={t('quiz.modal.insertTitle')} />
        </Form.Item>

        <Form.Item label={t('quiz.modal.description')} name="description" initialValue={quiz?.description || ''}>
          <Input placeholder={t('quiz.modal.insertDescription')} />
        </Form.Item>

        <Form.Item
          label={t('quiz.modal.subjectArea')}
          name="subjectArea"
          initialValue={quiz?.subjectArea?.targetName}
          rules={[
            {
              required: true,
              message: `${t('quiz.modal.chooseSubjectArea')}`,
            },
          ]}
        >
          <Select placeholder={t('quiz.modal.selectSubjectArea')}>
            {subjectAreas.map(sa => (
              <Option key={sa.targetName} value={sa.targetName}>
                {sa.targetName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={t('quiz.modal.subjects')}
          name="subjects"
          initialValue={quiz?.subjects?.map(subject => subject.targetName)}
          rules={[
            {
              required: true,
              message: `${t('quiz.modal.chooseSubject')}`,
            },
          ]}
        >
          <Select mode="multiple" placeholder={t('quiz.modal.selectSubjects')} onSearch={debounce(getSuggestions, 500)}>
            {subjects.map(s => (
              <Option key={s.targetName} value={s.targetName}>
                {s.targetName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label={t('quiz.modal.coverImage')} name="coverImage">
          <ImageUpload uniqueImageName={uniqueImageName} setUniqueImageName={setUniqueImageName} />
        </Form.Item>
      </Form>
    );
  };

  return (
    <>
      <Modal
        visible={visible}
        title={t(quiz?.id ? 'quiz.modal.editQuiz' : 'quiz.modal.newQuiz')}
        onCancel={onClose}
        footer={[
          <Button key="back" onClick={onClose}>
            {t('global.actions.cancel')}
          </Button>,
          <Button key="submit" type="primary" onClick={handleFormSubmit}>
            {t(quiz?.id ? 'quiz.update.saveQuiz' : 'quiz.modal.createQuiz')}
          </Button>,
        ]}
      >
        {renderForm()}
      </Modal>
    </>
  );
};

export default SaveQuizModal;
