import React, { FC } from 'react';
import { IconInterface } from './index';

const PlusIcon: FC<IconInterface> = ({
  name,
  style = {},
  width = '15',
  height = '15',
  viewBox = '0 0 15 15',
  className = '',
  fill = 'black',
  stroke = 'none',
  fillOpacity = '0.85',
}) => (
  <svg
    aria-label={name}
    className={className}
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.97437 1.37842H7.03687C6.95353 1.37842 6.91187 1.42008 6.91187 1.50342V6.40967H2.25598C2.17265 6.40967 2.13098 6.45133 2.13098 6.53467V7.47217C2.13098 7.5555 2.17265 7.59717 2.25598 7.59717H6.91187V12.5034C6.91187 12.5868 6.95353 12.6284 7.03687 12.6284H7.97437C8.0577 12.6284 8.09937 12.5868 8.09937 12.5034V7.59717H12.756C12.8393 7.59717 12.881 7.5555 12.881 7.47217V6.53467C12.881 6.45133 12.8393 6.40967 12.756 6.40967H8.09937V1.50342C8.09937 1.42008 8.0577 1.37842 7.97437 1.37842Z"
      fill={fill}
      fillOpacity={fillOpacity}
    />
  </svg>
);

export default PlusIcon;
