import React, { FC } from 'react';
import { IconInterface } from './index';

const FinnishFlagIcon: FC<IconInterface> = ({
  name,
  style = {},
  width = '16px',
  height = '16px',
  viewBox = '0 0 640 480',
  className = '',
}) => (
  <svg
    aria-label={name}
    className={className}
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill="#fff" d="M0 0h640v480H0z" />
    <path fill="#003580" d="M0 174.5h640v131H0z" />
    <path fill="#003580" d="M175.5 0h130.9v480h-131z" />
  </svg>
);

export default FinnishFlagIcon;
