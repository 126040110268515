import axios, { AxiosResponse } from 'axios';
import { Submission } from '../model/submission.model';
import { useMutation, UseMutationResult } from 'react-query';
import { Quiz } from '../model/quiz.model';

export const getUserSubmission = (studentId?: string | null): Promise<Submission> => {
  return axios
    .get<Submission>(`/api/submissions`, { params: { studentId } })
    .then(res => {
      return res.data;
    });
};

export const createSubmission = (entity: Submission): Promise<AxiosResponse<Submission>> => {
  return axios.post<Submission>(`/api/submissions`, entity);
};

export const useCreateSubmission = (onSuccess?: () => void): UseMutationResult<AxiosResponse<Quiz>, Error, Submission> => {
  return useMutation(async (submission: Submission) => axios.post('/api/submissions', submission), {
    onSuccess,
  });
};
