import React from 'react';
import DefaultImageFilledIcon from './DefaultImageFilledIcon';
import DefaultImageOutlinedIcon from './DefaultImageOutlinedIcon';
import NoQuizzesIcon from './NoQuizzesIcon';
import SubjectAreaIcon from './SubjectAreaIcon';
import EstonianFlagIcon from './EstonianFlagIcon';
import SubjectIcon from './SubjectIcon';
import UserIcon from './UserIcon';
import SchoolabyLogo from './SchoolabyLogo';
import ListViewIcon from './ListViewIcon';
import CardViewIcon from './CardViewIcon';
import EnglishFlagIcon from './EnglishFlagIcon';
import FinnishFlagIcon from './FinnishFlagIcon';
import UkrainianFlagIcon from './UkrainianFlagIcon';
import SearchIcon from './SearchIcon';
import UpIcon from './UpIcon';
import DownIcon from './DownIcon';
import DropdownIcon from './DropdownIcon';
import DragIcon from './DragIcon';
import MultipleChoiceChecboxIcon from './MultipleChoiceChecboxIcon';
import TrashIcon from './TrashIcon';
import PlusIcon from './PlusIcon';

export type IconName =
  | 'default-image-filled'
  | 'default-image-outlined'
  | 'no-quizzes-image'
  | 'subject-area'
  | 'estonian-flag'
  | 'english-flag'
  | 'finnish-flag'
  | 'ukrainian-flag'
  | 'subject'
  | 'user'
  | 'schoolaby-logo'
  | 'list-view'
  | 'card-view'
  | 'search'
  | 'up'
  | 'down'
  | 'dropdown'
  | 'drag'
  | 'multiple-choice-checkbox'
  | 'trash'
  | 'plus'
  | '';

export interface IconInterface {
  style?: React.CSSProperties;
  fill?: string;
  stroke?: string;
  width?: string;
  height?: string;
  viewBox?: string;
  className?: string;
  name: IconName;
  role?: string;
  fillOpacity?: string;
  ariaLabel?: string;
}

/* eslint-disable complexity */
const Icon = (props: IconInterface): JSX.Element | null => {
  switch (props.name) {
    case 'default-image-filled':
      return <DefaultImageFilledIcon {...props} />;
    case 'default-image-outlined':
      return <DefaultImageOutlinedIcon {...props} />;
    case 'no-quizzes-image':
      return <NoQuizzesIcon {...props} />;
    case 'subject-area':
      return <SubjectAreaIcon {...props} />;
    case 'estonian-flag':
      return <EstonianFlagIcon {...props} />;
    case 'english-flag':
      return <EnglishFlagIcon {...props} />;
    case 'finnish-flag':
      return <FinnishFlagIcon {...props} />;
    case 'ukrainian-flag':
      return <UkrainianFlagIcon {...props} />;
    case 'subject':
      return <SubjectIcon {...props} />;
    case 'user':
      return <UserIcon {...props} />;
    case 'schoolaby-logo':
      return <SchoolabyLogo {...props} />;
    case 'list-view':
      return <ListViewIcon {...props} />;
    case 'card-view':
      return <CardViewIcon {...props} />;
    case 'search':
      return <SearchIcon {...props} />;
    case 'up':
      return <UpIcon {...props} />;
    case 'down':
      return <DownIcon {...props} />;
    case 'dropdown':
      return <DropdownIcon {...props} />;
    case 'drag':
      return <DragIcon {...props} />;
    case 'multiple-choice-checkbox':
      return <MultipleChoiceChecboxIcon {...props} />;
    case 'trash':
      return <TrashIcon {...props} />;
    case 'plus':
      return <PlusIcon {...props} />;
    default:
      return null;
  }
};

export default Icon;
