import React, { FC } from 'react';
import { IconInterface } from './index';

const DefaultImageFilledIcon: FC<IconInterface> = ({
  name,
  style = { fill: '#CBCBCB !important' },
  width = '110px',
  height = '110px',
  viewBox = '0 0 54 40',
  className = '',
}) => (
  <svg
    aria-label={name}
    className={className}
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd" id="s-Image_24-Page-1" stroke="none" strokeWidth="1">
      <g fill="#CBCBCB" id="s-Image_24-Components" transform="translate(-865.000000, -1458.000000)">
        <g id="s-Image_24-Page-1-Copy-5" transform="translate(865.000000, 1458.000000)">
          <path
            d="M2.74576271,37.2093023 L51.2542373,37.2093023 L51.2542373,2.79069767 L2.74576271,2.79069767 L2.74576271,37.2093023 Z M52.6271186,0 L1.37288136,0 C0.615966102,0 0,0.626046512 0,1.39534884 L0,38.6046512 C0,39.3739535 0.615966102,40 1.37288136,40 L52.6271186,40 C53.3840339,40 54,39.3739535 54,38.6046512 L54,1.39534884 C54,0.626046512 53.3840339,0 52.6271186,0 Z"
            id="s-Image_24-Fill-1"
            style={style}
          />
          <path
            d="M14.9995483,9.471768 C16.3944349,9.471768 17.5291354,10.6055651 17.5291354,11.9995483 C17.5291354,13.3944349 16.3944349,14.528232 14.9995483,14.528232 C13.6055651,14.528232 12.4708646,13.3944349 12.4708646,12.0004517 C12.4708646,10.6055651 13.6055651,9.471768 14.9995483,9.471768 M14.9995483,17 C17.7577017,17 20,14.7577017 20,12.0004517 C20,9.24410516 17.7577017,7 14.9995483,7 C12.2422983,7 10,9.24320173 10,11.9995483 C10,14.7567983 12.2422983,17 14.9995483,17"
            id="s-Image_24-Fill-3"
            style={style}
          />
          <path
            d="M6.40718098,36 C6.74842583,36 7.07842085,35.8780915 7.33529197,35.6571897 L22.2985036,22.7770565 L31.646175,31.9156082 C32.1946042,32.4509055 33.0861533,32.4499889 33.6345825,31.9156082 C34.1820742,31.3784777 34.1820742,30.5067863 33.6345825,29.971489 L29.4440207,25.8742647 L37.4332751,17.3214236 L47.6434335,26.4737244 C48.2152999,26.9861066 49.1068489,26.9466926 49.6309035,26.3884801 C49.8849622,26.117165 50.0162102,25.7651887 49.998398,25.3985467 C49.9815232,25.0309881 49.8202757,24.6927609 49.5437173,24.4461942 L38.2929497,14.3626227 C38.0238912,14.1224722 37.6845214,13.9794818 37.280465,14.0023969 C36.9054706,14.0198124 36.5586008,14.1783851 36.3054797,14.4497002 L27.4537383,23.9273957 L23.3541127,19.9181655 C22.8309956,19.4094498 21.9863208,19.381035 21.4313292,19.8595028 L5.4781325,33.5929953 C5.19594926,33.8358956 5.02626432,34.1713731 5.00282718,34.5370984 C4.97939003,34.904657 5.10220068,35.25755 5.35157191,35.5343647 C5.61875538,35.8295115 6.00312458,36 6.40718098,36"
            id="s-Image_24-Fill-4"
            style={style}
          />
        </g>
      </g>
    </g>
  </svg>
);

export default DefaultImageFilledIcon;
