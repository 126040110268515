import axios from 'axios';

export interface IDeepLinkingResponseParams {
  url: string;
  jwt: string;
}

export const getDeepLinkingResponseParams = (quizId: number): Promise<IDeepLinkingResponseParams> => {
  return axios.get<IDeepLinkingResponseParams>(`/api/deeplink/response-params?quizId=${quizId}`).then(res => {
    return res.data;
  });
};
