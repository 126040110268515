import axios, { AxiosResponse } from 'axios';
import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { Quiz } from '../model/quiz.model';
import { Page, PageRequest } from '../shared/page';
import { QUIZ_KEY, QUIZZES_KEY } from '../config/reactQueryKeyConstants';
import { PUBLISH, UNPUBLISH } from '../shared/constants';

type PublishAction = typeof PUBLISH | typeof UNPUBLISH;

export const useGetQuizzes = (pageRequest?: PageRequest): UseQueryResult<Page<Quiz>, Error> =>
  useQuery<Page<Quiz>, Error>(
    [QUIZZES_KEY, pageRequest],
    async () =>
      axios
        .get<Page<Quiz>>(`/api/quizzes`, { params: pageRequest })
        .then(res => res.data),
    {
      onError(error) {
        console.error('Could not fetch quizzes', error);
      },
    }
  );

export const useGetQuiz = (id: number | string): UseQueryResult<Quiz, Error> =>
  useQuery<Quiz, Error>([QUIZ_KEY, +id], async () => axios.get<Quiz>(`/api/quizzes/${id}`).then(res => res?.data), { enabled: !!id });

export const getQuiz = (id: number | string): Promise<Quiz> => {
  return axios.get<Quiz>(`/api/quizzes/${id}`).then(res => {
    return res.data;
  });
};

export const createQuiz = (entity: Quiz): Promise<AxiosResponse<Quiz>> => {
  return axios.post<Quiz>(`/api/quizzes`, entity);
};

export const updateQuiz = (entity: Quiz): Promise<AxiosResponse<Quiz>> => {
  return axios.put<Quiz>(`/api/quizzes`, entity);
};

export const useDeleteQuiz = (id: string): UseMutationResult => {
  const queryClient = useQueryClient();
  return useMutation(async () => axios.delete(`/api/quizzes/${id}`), {
    onSuccess() {
      queryClient.invalidateQueries(QUIZZES_KEY).catch(error => console.error('Failed to invalidate queries', error));
    },
  });
};

export const usePublishQuiz = (id: string): UseMutationResult<AxiosResponse<Quiz>, Error, PublishAction> =>
  useMutation(async (publishAction: PublishAction) => axios.post(`/api/quizzes/${publishAction}/${id}`));
