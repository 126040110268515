import React, { FC } from 'react';
import { IconInterface } from './index';

const UserIcon: FC<IconInterface> = ({
  name,
  style = {},
  width = '18px',
  height = '18px',
  viewBox = '0 0 18 18',
  className = '',
  fill = 'none',
  stroke = '#6B33F9',
}) => (
  <svg
    aria-label={name}
    className={className}
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1213 7.37132C11.6839 6.80871 12 6.04565 12 5.25C12 4.45435 11.6839 3.69129 11.1213 3.12868C10.5587 2.56607 9.79565 2.25 9 2.25C8.20435 2.25 7.44129 2.56607 6.87868 3.12868C6.31607 3.69129 6 4.45435 6 5.25C6 6.04565 6.31607 6.80871 6.87868 7.37132C7.44129 7.93393 8.20435 8.25 9 8.25C9.79565 8.25 10.5587 7.93393 11.1213 7.37132Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.28769 12.0377C6.27226 11.0531 7.60761 10.5 9 10.5C10.3924 10.5 11.7277 11.0531 12.7123 12.0377C13.6969 13.0223 14.25 14.3576 14.25 15.75H3.75C3.75 14.3576 4.30312 13.0223 5.28769 12.0377Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UserIcon;
