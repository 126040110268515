import React, { FC, ReactNode } from 'react';
import { Button, Form, Space } from 'antd';
import { useTranslation } from 'react-i18next';

interface IProps {
  errors: ReactNode[];
  add: () => void;
}

const ChoiceInputListFooter: FC<IProps> = ({ errors, add }) => {
  const { t } = useTranslation();

  return (
    <>
      <Form.ErrorList errors={errors} />
      <Space>
        <Button onClick={add} type="primary" className={'border-radius-0'}>
          {t('quiz.update.questionForm.addMoreOptions')}
        </Button>
      </Space>
    </>
  );
};

export default ChoiceInputListFooter;
