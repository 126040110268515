import React, { FC } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import { Portfolio } from './routes/portfolio';
import { AUTHORIZATION_JWT } from './config/constants';
import QuizUpdate from './quiz/update-quiz/quiz-update';
import { QuizEditProvider } from './shared/context/quiz-edit-context';
import config from './config';
import QuizPreview from './quiz/preview/quiz-preview';
import QuizLaunch from './quiz/launch/quiz-launch';
import PrivateRoute from "./shared/auth/private-route";
import { INSTRUCTOR } from "./shared/constants";

const Routes: FC = () => {
  const Component = () => {
    const { Content } = Layout;
    const { t } = useTranslation();
    return (
      <Content>
        <div className="App-header">
          <h2>
            {`${t('logInThrough')}`} <a href={config.SCHOOLABY_URL}>Schoolaby</a>
          </h2>
        </div>
      </Content>
    );
  };

  const saveJwtToLocalStorage = () => {
    const jwt = new URLSearchParams(window.location.search).get('jwt');
    jwt && localStorage.setItem(AUTHORIZATION_JWT, jwt);
  };

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Component} />
        <PrivateRoute exact path="/portfolio" component={Portfolio} authorities={[INSTRUCTOR]} />
        <Route exact path="/quiz/:id" component={QuizPreview} />
        <Route exact path="/quiz/:id/launch" component={QuizLaunch} />
        <PrivateRoute exact path="/quiz/:id/edit" component={() => <QuizEditProvider>
          <QuizUpdate />
        </QuizEditProvider>} authorities={[INSTRUCTOR]} />
        <Route exact path="/redirectjwt">
          {saveJwtToLocalStorage()}
          <Redirect to={'/portfolio'} />
        </Route>
      </Switch>
    </Router>
  );
};

export default Routes;
