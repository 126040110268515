import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Row, Space } from 'antd';
import { FormListFieldData } from 'antd/es/form/FormList';
import Icon from '../../../shared/icons';
import PreviewButton from '../../shared/preview-button';
import Preview from '../../shared/preview';
import MathJaxTutorialModal from '../../shared/math-jax-tutorial-modal';

interface IProps {
  choiceField: FormListFieldData;
  choiceFieldsLength: number;
  remove: (index: number | number[]) => void;
  choiceRightSide: JSX.Element;
  index: number;
}

const BaseChoiceInput: FC<IProps> = ({ choiceField, choiceFieldsLength, remove, choiceRightSide, index }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string>();
  const [preview, setPreview] = useState<boolean>(false);
  const ref = useRef<any>();

  useEffect(() => {
    if (value === undefined) {
      setValue(ref?.current?.input?.value);
    }
  }, [ref?.current]);

  return (
    <Row justify={'start'} className={'choice-row'}>
      <p className={'choice-label'}>
        {t('quiz.update.questionForm.answerOption')} {String.fromCharCode(index + 65)}
      </p>
      <div className="choice">
        <div className="choice-input">
          <Form.Item
            name={[choiceField.name, 'choiceString']}
            rules={[
              {
                required: true,
                message: `${t('quiz.update.questionForm.answerRequired')}`,
              },
            ]}
          >
            <Input
              data-testid={'question-textbox'}
              size={'large'}
              className={'border-radius-0'}
              placeholder={t('quiz.update.questionForm.answer')}
              onChange={event => setValue(event.target.value)}
              addonAfter={
                <Space size={6}>
                  <PreviewButton setPreview={setPreview} preview={preview} />
                  <MathJaxTutorialModal />
                </Space>
              }
              ref={ref}
            />
          </Form.Item>
        </div>
        <div className="choice-options">
          <div className="remove-choice" onClick={() => choiceFieldsLength > 2 && remove(choiceField.name)}>
            <Icon name={'trash'} ariaLabel={t('quiz.update.questionForm.removeOption')} />
          </div>
          <div className="choice-right-side">{choiceRightSide}</div>
        </div>
        <Preview preview={preview} value={value} />
      </div>
    </Row>
  );
};

export default BaseChoiceInput;
