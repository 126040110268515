import './portfolio.scss';
import { Button, Card, Col, Layout, Row, Select, Skeleton, Typography } from 'antd';
import React, { FC, useState } from 'react';
import { QuizCard } from '../shared/layout/quiz/quiz-card';
import { useGetQuizzes } from '../api/quizApi';
import { PageRequest } from '../shared/page';
import { CustomPagination } from '../shared/layout/pagination/custom-pagination';
import { useTranslation } from 'react-i18next';
import Title from 'antd/lib/typography/Title';
import SaveQuizModal from '../quiz/save-quiz-modal/save-quiz-modal';
import { useQueryParams } from '../shared/hooks/useQueryParams';
import Icon from '../shared/icons';
import Header from '../shared/layout/header/header';

export const Portfolio: FC = () => {
  const { Content } = Layout;
  const { t } = useTranslation();
  const [pageRequest, setPageRequest] = useState<PageRequest>({ page: 1, size: 12 });
  const [saveQuizModalVisible, setSaveQuizModalVisible] = useState<boolean>(false);
  const isDeepLinking = !!useQueryParams().get('deepLinking');
  const { Text } = Typography;
  const { Option } = Select;
  const { data: quizzes, isLoading } = useGetQuizzes(pageRequest);

  const OpenSaveQuizModalButton = () => {
    return !isDeepLinking ? (
      <Button type="primary" className={'open-save-quiz-modal-button font-semibold text-sm'} onClick={() => setSaveQuizModalVisible(true)}>
        {t('portfolio.addQuiz')}
      </Button>
    ) : null;
  };

  const renderLoadingCards = () => {
    const cards = [];
    for (let i = 0; i < 15; i++) {
      cards.push(
        <div className={'card-skeleton'}>
          <Skeleton.Image className={'card-skeleton-image'} />
          <Card loading={true} />
        </div>
      );
    }
    return cards;
  };

  const Quizzes = () => (
    <Row className={'quizzes'} gutter={[16, 16]}>
      {!isLoading ? (
        quizzes?.content?.map((quiz, i) => (
          <Col key={i} className={'quiz-card-col'}>
            <QuizCard quiz={quiz} isDeepLinking={isDeepLinking} />
          </Col>
        ))
      ) : (
        <>
          {renderLoadingCards().map((card, i) => (
            <Col key={i} className={'quiz-card-col'}>
              <>{card}</>
            </Col>
          ))}
        </>
      )}
      {!quizzes?.totalElements && (
        <Col xs={24} className={'no-quizzes'}>
          <div>
            <Icon name={'no-quizzes-image'} />
            <div>
              <div className={'no-quizzes-text font-bold text-xl'}>{t('portfolio.noQuizzes')}</div>
            </div>
            <div>
              <Text className={'fill-the-empty-space-text font-normal text-base'} type={'secondary'}>
                {t('portfolio.fillTheEmptySpace')}
              </Text>
            </div>
            <OpenSaveQuizModalButton />
          </div>
        </Col>
      )}
    </Row>
  );

  const calculateTotalQuizzesText = () => {
    const quizCount = quizzes?.totalElements || 0;
    if (quizzes?.totalElements && quizzes?.totalElements === 1) {
      return `${quizCount} ${t('portfolio.quiz')}`;
    }
    return `${quizCount} ${t('portfolio.quizzes')}`;
  };

  return (
    <>
      <Header className={'quizzeria-component-width'} rightComponent={<OpenSaveQuizModalButton />} />
      <Content className={'portfolio quizzeria-content'}>
        {/* <QuizFilter /> - not implemented yet */}
        <Row justify={'space-between'} className={'quiz-cards-container'}>
          <Col xs={24} sm={8}>
            {isLoading ? (
              <Skeleton paragraph={false} />
            ) : (
              <Title className={'total-quizzes-count'} level={4}>
                {calculateTotalQuizzesText()}
              </Title>
            )}
          </Col>
          <Col xs={24} sm={16} className={'sort-container'}>
            {isLoading ? (
              <Skeleton className={'sort-skeleton'} title={{ width: 200 }} paragraph={false} />
            ) : (
              <span className={'sort'}>
                {/* Not implemented yet */}
                {/* <Text type="secondary">{t('portfolio.sort')}:</Text> */}
                {/* <Select className={'sort-selector'} defaultValue="myQuestionsFirst" bordered={false}> */}
                {/*  <Option value="myQuestionsFirst">{t('portfolio.myQuestionsFirst')}</Option>*/}
                {/* </Select> */}
                {/* <Icon name={'card-view'} className={'card-view-icon'} /> */}
                {/* <Icon name={'list-view'} className={'list-view-icon'} /> */}
              </span>
            )}
          </Col>
          <Quizzes />
          <Col className={'pagination'} xs={24}>
            <CustomPagination page={quizzes} pageRequest={pageRequest} setPageRequest={setPageRequest} />
          </Col>
        </Row>
        <SaveQuizModal visible={saveQuizModalVisible} onClose={() => setSaveQuizModalVisible(false)} />
      </Content>
    </>
  );
};
