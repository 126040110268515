import React, { FC, RefObject, useState } from 'react';
import QuestionSaveForm from './question-save-form/question-save-form';
import { Question } from '../../model/question.model';
import './question-box.scss';
import { FormInstance } from 'antd';
import { Answer } from '../../model/answer.model';
import QuestionAnsweringView from './question/question-answering-view';
import QuestionDetailView from './question/question-detail-view';

interface IQuestionBox {
  question: Question;
  questionType: string;
  sequenceNumber: number;
  inForm?: boolean;
  isPreview?: boolean;
  editable?: boolean;
  formRef?: RefObject<FormInstance>;
  answer?: Answer;
}

const QuestionBox: FC<IQuestionBox> = ({ question, questionType, sequenceNumber, inForm, isPreview, editable, formRef, answer }) => {
  const [isInEditView, setIsInEditView] = useState<boolean>(false);

  const renderModifiableForm = () => {
    return isInEditView ? (
      <QuestionSaveForm
        questionType={questionType}
        existingQuestion={question}
        onClose={() => setIsInEditView(false)}
        sequenceNumber={sequenceNumber}
      />
    ) : (
      <QuestionDetailView
        onEdit={() => setIsInEditView(true)}
        question={question}
        sequenceNumber={sequenceNumber}
        editable={editable}
        isPreview={isPreview}
        answer={answer}
      />
    );
  };

  const renderAnswerForm = () => {
    return <QuestionAnsweringView question={question} sequenceNumber={sequenceNumber} formRef={formRef} />;
  };

  return <div className={'question-box'}>{inForm ? renderAnswerForm() : renderModifiableForm()}</div>;
};

export default QuestionBox;
