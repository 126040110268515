import React, { FC } from 'react';
import InfoModal from '../../shared/layout/info-modal/info-modal';
import { useTranslation } from 'react-i18next';

const MathJaxTutorialModal: FC = () => {
  const { t } = useTranslation();
  return (
    <InfoModal
      buttonClassName={'textarea-button'}
      modalContent={
        <>
          <p className={'pre-wrap mt-1'}>{t('quiz.update.questionForm.mathJaxTutorial')}</p>
          <ul>
            <li>
              <a
                href={'https://math.meta.stackexchange.com/questions/5020/mathjax-basic-tutorial-and-quick-reference'}
                target={'_blank'}
                rel={'noreferrer'}
              >
                {'MathJax basic tutorial and quick reference'}
              </a>
            </li>
          </ul>
        </>
      }
    />
  );
};

export default MathJaxTutorialModal;
