import React, { FC } from 'react';
import { Col } from 'antd';
import MathJax from 'react-mathjax-preview';

type PreviewProps = {
  preview: boolean;
  value: string | undefined;
};

const Preview: FC<PreviewProps> = ({ preview, value }): JSX.Element =>
  preview ? (
    <Col className={'preview'} xs={24}>
      <MathJax math={value} />
    </Col>
  ) : (
    <></>
  );

export default Preview;
