import React from 'react';
import { message } from 'antd';

export const successMessage = (value: string): void => {
  message.success(value).then(
    () => {
      return;
    },
    () => {
      return;
    }
  );
};

export const errorMessage = (value: string): void => {
  message.error(value).then(
    () => {
      return;
    },
    () => {
      return;
    }
  );
};
