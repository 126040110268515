import React, { FC } from 'react';
import { IconInterface } from './index';

const EstonianFlagIcon: FC<IconInterface> = ({
  name,
  style = {},
  width = '16px',
  height = '16px',
  viewBox = '0 0 16 16',
  className = '',
}) => (
  <svg
    aria-label={name}
    className={className}
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16 0.000244141H0V5.33337H16V0.000244141Z" fill="#4173CD" />
    <path d="M16 10.6665H0V15.9997H16V10.6665Z" fill="#F5F5F5" />
    <path d="M16 5.33347H0V10.6666H16V5.33347Z" fill="#464655" />
  </svg>
);

export default EstonianFlagIcon;
