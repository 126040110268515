import React, { FC } from 'react';
import { IconInterface } from './index';

const SubjectAreaIcon: FC<IconInterface> = ({
  name,
  style = {},
  width = '18px',
  height = '18px',
  viewBox = '0 0 18 18',
  className = '',
  fill = 'none',
  stroke = '#6B33F9',
}) => (
  <svg
    aria-label={name}
    className={className}
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.25 8.25H3.75M14.25 8.25C14.6478 8.25 15.0294 8.40804 15.3107 8.68934C15.592 8.97064 15.75 9.35218 15.75 9.75V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V9.75C2.25 9.35218 2.40804 8.97064 2.68934 8.68934C2.97064 8.40804 3.35218 8.25 3.75 8.25M14.25 8.25V6.75C14.25 6.35218 14.092 5.97064 13.8107 5.68934C13.5294 5.40804 13.1478 5.25 12.75 5.25M3.75 8.25V6.75C3.75 6.35218 3.90804 5.97064 4.18934 5.68934C4.47064 5.40804 4.85218 5.25 5.25 5.25M12.75 5.25V3.75C12.75 3.35218 12.592 2.97064 12.3107 2.68934C12.0294 2.40804 11.6478 2.25 11.25 2.25H6.75C6.35218 2.25 5.97064 2.40804 5.68934 2.68934C5.40804 2.97064 5.25 3.35218 5.25 3.75V5.25M12.75 5.25H5.25"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SubjectAreaIcon;
