import './quiz-card.scss';
import React, { FC, useEffect } from 'react';
import { Button, Card, Dropdown, Image, Menu, Row, Space, Tag, Tooltip, Typography } from 'antd';
import { Quiz } from '../../../model/quiz.model';
import { EllipsisOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Meta from 'antd/lib/card/Meta';
import { useHistory } from 'react-router-dom';
import { calculateQuizMaxPoints, getAuthorFullName } from '../../util/quiz-util';
import Title from 'antd/es/typography/Title';
import { EducationalAlignment } from '../../../model/educational-alignment.model';
import cloneDeep from 'lodash/cloneDeep';
import Icon from '../../icons';
import { PRIMARY_COLOR, SECONDARY_COLOR, SUBJECT_TAG_COLOR } from '../../style-constants';
import { useDeleteQuiz } from '../../../api/quizApi';
import { useAccountState } from '../../context/root-context';
import { GRAY } from '../../color-constants';

export interface IQuizCard {
  quiz: Quiz;
  isDeepLinking?: boolean;
}

export const QuizCard: FC<IQuizCard> = ({ quiz, isDeepLinking }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { Text, Paragraph } = Typography;
  const { account } = useAccountState();
  const deleteQuiz = useDeleteQuiz(String(quiz.id));

  useEffect(() => {
    if (deleteQuiz?.isError) {
      console.error('Could not remove quiz', deleteQuiz.error);
    }
  }, [deleteQuiz?.isError]);

  const SubjectArea = () => (
    <Space align={'center'}>
      <Icon className={'subject-area-icon'} name={'subject-area'} />
      <Title level={5}>
        <Text className={'subject-area-text font-semibold text-sm'}>{quiz?.subjectArea?.targetName}</Text>
      </Title>
    </Space>
  );

  const Subjects = () => {
    const firstSubjects: EducationalAlignment[] | undefined = quiz?.subjects && cloneDeep(quiz?.subjects).slice(0, 2);
    const lastSubjects: EducationalAlignment[] | undefined = quiz?.subjects && cloneDeep(quiz?.subjects).splice(2);
    return (
      <span className={'subjects'}>
        {firstSubjects?.map((subject, idx) => (
          <Tag key={idx} data-testid={'subject-tag'} className={'subject'} color={SUBJECT_TAG_COLOR}>
            <Text ellipsis={{ tooltip: subject.targetName }}>{subject.targetName}</Text>
          </Tag>
        ))}
        {lastSubjects?.length ? (
          <Tooltip title={lastSubjects?.map(subject => `- ${subject?.targetName}`).join('\n')}>
            <Tag className={'subject'} data-testid={'subject-tag'} color={SUBJECT_TAG_COLOR}>
              <Text>{`+${lastSubjects?.length}`}</Text>
            </Tag>
          </Tooltip>
        ) : null}
      </span>
    );
  };

  const MenuDropdown = () => {
    const menu = (
      <Menu>
        <Menu.Item onClick={() => history.push(`/quiz/${quiz?.id || 'undefined'}/edit`)}>{t('global.quizCard.edit')}</Menu.Item>
        <Menu.Item onClick={() => deleteQuiz.mutateAsync({})}>{t('global.quizCard.delete')}</Menu.Item>
      </Menu>
    );
    return quiz?.creator?.id === account?.id ? (
      <Dropdown className={'menu-dropdown'} trigger={['click']} overlay={menu}>
        <EllipsisOutlined />
      </Dropdown>
    ) : (
      <></>
    );
  };

  const CardCover = () => (
    <span className={'card-image-container'}>
      <Image alt={quiz.title} preview={false} fallback="no_image.png" src={quiz?.image ? `/api/files/${quiz.image}` : 'no_image.png'} />
      <Row className={'card-cover-tags'}>
        <Tag className={'score-maximum'} color={SECONDARY_COLOR}>
          {`${calculateQuizMaxPoints(quiz.questions)} ${t('quiz.points-abbr')}`}
        </Tag>
        {quiz.creator?.id === account?.id && quiz.published && (
          <Tag className={'published-tag'} color={PRIMARY_COLOR}>
            {t('global.published')}
          </Tag>
        )}
      </Row>
      <MenuDropdown />
      <Subjects />
    </span>
  );

  const Author = () => (
    <Text type={'secondary'}>
      <Space align={'center'} className={'author font-normal text-sm'}>
        <Icon name={'user'} className={`author-icon ${!quiz.creator ? 'hidden' : ''}`} stroke={GRAY} />
        <span>{getAuthorFullName(quiz)}</span>
      </Space>
    </Text>
  );

  const Description = () => (
    <Paragraph className={'description font-normal text-sm'} type={'secondary'} ellipsis={{ rows: 3 }}>
      {quiz.description}
    </Paragraph>
  );

  return (
    <Card className={'quiz-card'} data-testid={'quiz-card'} bordered={false} cover={<CardCover />}>
      <Meta title={<div className={'title font-bold text-base'}>{quiz.title}</div>} description={<SubjectArea />} />
      <Author />
      <Description />
      <div className={'card-footer'}>
        {isDeepLinking ? (
          <>
            <Button block onClick={() => history.push(`/quiz/${quiz?.id || 'undefined'}?deepLinking=true`)}>
              {t('global.quizCard.selectForAssignment')}
            </Button>
          </>
        ) : (
          //  Not implemented yet
          // <Button block>{t('global.quizCard.preview')}</Button>
          <></>
        )}
      </div>
    </Card>
  );
};
