import axios, { AxiosRequestConfig } from 'axios';
import { AUTHORIZATION_JWT } from './constants';

const setupAxiosInterceptors = () => {
  const onRequestSuccess = (config: AxiosRequestConfig) => {
    const token = localStorage.getItem(AUTHORIZATION_JWT);
    if (token && config.headers?.Authorization !== 'none') {
      config.headers.Authorization = `Bearer ${token}`;
    } else if (config.headers?.Authorization === 'none') {
      delete config.headers.Authorization;
    }
    return config;
  };
  axios.interceptors.request.use(onRequestSuccess);
};

export default setupAxiosInterceptors;
