import React, { FC } from 'react';
import './header.scss';
import { Divider, Layout, Space } from 'antd';
import LanguageSelector from './language-selector';
import Icon from '../../icons';
import { useHistory } from 'react-router-dom';
import { useAccountState } from '../../context/root-context';
import { LEARNER } from '../../constants';

interface IProps {
  rightComponent?: JSX.Element;
  className?: string;
}

const Header: FC<IProps> = ({ rightComponent, className }) => {
  const history = useHistory();
  const { account } = useAccountState();
  const disableLogoRedirect = account && account.authorities?.includes(LEARNER);

  return (
    <Layout.Header>
      <div className={`${className || ''} header`}>
        <div className={'header-content'}>
          <div className={'logo font-semibold'} onClick={() => !disableLogoRedirect && history.push('/portfolio')}>
            <img alt={'Quizzeria logo'} src={'/quizzeria.svg'} />
          </div>
          <Divider className={'divider'} type={'vertical'} />
          <div className={'product-by-schoolaby-box'}>
            <div className={'product-by font-bold'}>Product by</div>
            <Icon name={'schoolaby-logo'} />
          </div>
        </div>
        <Space wrap>
          <span className={'language-selector'}>
            <LanguageSelector />
          </span>
          <span>{rightComponent}</span>
        </Space>
      </div>
    </Layout.Header>
  );
};

export default Header;
