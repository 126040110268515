export const INSTRUCTOR = 'ROLE_INSTRUCTOR';
export const USER = 'ROLE_USER';
export const ADMIN = 'ROLE_ADMIN';
export const TEACHING_ASSISTANT = 'ROLE_TEACHING_ASSISTANT';
export const LEARNER = 'ROLE_LEARNER';

export const CORRECT = 'correct';
export const PARTIALLY_CORRECT = 'partially-correct';
export const INCORRECT = 'incorrect';

export const PUBLISH = 'publish';
export const UNPUBLISH = 'unpublish';
