import React, { FC } from 'react';
import { IconInterface } from './index';

const UkrainianFlagIcon: FC<IconInterface> = ({
  name,
  style = {},
  width = '16px',
  height = '16px',
  viewBox = '0 0 16 16',
  className = '',
}) => (
  <svg
    aria-label={name}
    className={className}
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16 0.000244141H0 V7.9H16V0.000244141 Z" fill="#005BBB" />
    <path d="M16 7.9H0V15.9997H16V10.6665 Z" fill="#FFD500" />
  </svg>
);

export default UkrainianFlagIcon;
