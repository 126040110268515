import React, { FC, ReactNode, RefObject } from 'react';
import { Question } from '../../../model/question.model';
import BaseQuestionView from './base-question-view';
import { Checkbox, Col, Form, FormInstance, Input, Radio, Space } from 'antd';
import { StoreValue } from 'antd/lib/form/interface';
import { isTextBasedQuestion } from '../../../shared/util/quiz-util';
import { useTranslation } from 'react-i18next';
import { FormListFieldData } from 'antd/es/form/FormList';
import { MULTIPLE_CHOICE_CHECKBOX, MULTIPLE_CHOICE_RADIO } from '../../../config/constants';
import { Choice } from '../../../model/choice.model';
import MathJax from 'react-mathjax-preview';

interface IProps {
  sequenceNumber: number;
  question: Question;
  formRef?: RefObject<FormInstance>;
}

const QuestionAnsweringView: FC<IProps> = ({ sequenceNumber, question, formRef }: IProps) => {
  const { t } = useTranslation();

  const renderBaseFormChoice = (rightSideComponent: JSX.Element, choice: FormListFieldData) => (
    <Col key={choice.key} xs={24} md={12} className={'choice-wrapper'}>
      <Form.Item noStyle valuePropName={'checked'} name={[choice.name, 'correct']}>
        {rightSideComponent}
      </Form.Item>
    </Col>
  );

  const renderMultipleChoiceCheckboxFormChoices = (choices: FormListFieldData[], errors: ReactNode[]) => (
    <>
      {choices.map(choice =>
        renderBaseFormChoice(
          <Space direction={'horizontal'} align={'center'}>
            <Checkbox>
              <MathJax math={question.choices?.[choice.key].choiceString} style={{display: 'inline-block'}} />
            </Checkbox>
          </Space>,
          choice
        )
      )}
      <Form.ErrorList errors={errors} />
    </>
  );

  const manageRadioButtons = (index: number) => {
    formRef?.current?.getFieldValue('answers')[sequenceNumber - 1].choices.map((choiceRef: Choice, choiceRefIndex: number) => {
      choiceRef.correct = choiceRefIndex === index;
      return choiceRef;
    });
  };

  const renderMultipleChoiceRadioFormChoices = (choices: FormListFieldData[], errors: ReactNode[]) => (
    <Radio.Group>
      {choices.map((choice, i) =>
        renderBaseFormChoice(
          <Space direction={'horizontal'} align={'center'}>
            <Radio value={choice.key} onChange={() => manageRadioButtons(i)}>
              <MathJax math={question.choices?.[choice.key].choiceString} style={{display: 'inline-block'}} />
            </Radio>
          </Space>,
          choice
        )
      )}
      <Form.ErrorList errors={errors} />
    </Radio.Group>
  );

  const renderFormChoicesByQuestionType = (choices: FormListFieldData[], errors: ReactNode[]) => {
    if (question.questionType === MULTIPLE_CHOICE_CHECKBOX) {
      return renderMultipleChoiceCheckboxFormChoices(choices, errors);
    } else if (question.questionType === MULTIPLE_CHOICE_RADIO) {
      return renderMultipleChoiceRadioFormChoices(choices, errors);
    }
  };

  const renderFormText = () => (
    <Col xs={12} md={12} className={'choice-wrapper'}>
      <Form.Item noStyle name={[sequenceNumber - 1, 'textAnswer']} required>
        <Input />
      </Form.Item>
    </Col>
  );

  const renderFormChoices = () => (
    <Form.List
      name={[sequenceNumber - 1, 'choices']}
      initialValue={question.choices?.map(choice => ({
        ...choice,
        correct: false,
      }))}
      rules={[
        {
          async validator(_, choices) {
            if (question.required && choices?.filter((choice: StoreValue) => choice?.correct === true)?.length < 1) {
              return Promise.reject(new Error(t('quiz.answeringBody.thisAnswerIsRequired')));
            }
          },
        },
      ]}
    >
      {(choices, { add, remove }, { errors }) => renderFormChoicesByQuestionType(choices, errors)}
    </Form.List>
  );

  const renderForm = () => {
    return isTextBasedQuestion(question) ? renderFormText() : renderFormChoices();
  };

  return (
    <BaseQuestionView
      dataTestid={'question-answering-view'}
      sequenceNumber={sequenceNumber}
      question={question}
      choicesElement={renderForm()}
    />
  );
};

export default QuestionAnsweringView;
