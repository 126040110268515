import React, { FC } from 'react';
import { Col, Row } from 'antd';
import './base-question.scss';

interface IProps {
  sequenceNumber: number;
  sequenceNumberClass?: string;
  children: JSX.Element | JSX.Element[];
  className?: string;
  dataTestid?: string;
}

export const BaseQuestion: FC<IProps> = ({
  sequenceNumber,
  sequenceNumberClass = '',
  children,
  className = '',
  dataTestid = '',
}: IProps) => {
  return (
    <Row data-testid={dataTestid} className={`quizzeria-question-container base-question ${className}`}>
      <Col className={'sequence-number-col'} xs={3} md={2} xxl={1}>
        <div className={`sequence-number text-xl font-bold ${sequenceNumberClass}`}>{sequenceNumber}</div>
      </Col>
      {children}
    </Row>
  );
};

export default BaseQuestion;
