import React, { FC } from 'react';
import { IconInterface } from './index';

const DefaultImageFilledIcon: FC<IconInterface> = ({ name }) => (
  <svg width="301" height="169" viewBox="0 0 301 169" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label={name}>
    <g clipPath="url(#clip0)">
      <path
        d="M280.179 158.609C280.098 161.642 279.59 164.649 278.67 167.541C278.649 167.608 278.627 167.674 278.605 167.741H272.972C272.978 167.681 272.984 167.614 272.99 167.541C273.365 163.223 271.22 137.257 268.631 132.763C268.857 133.128 280.616 144.89 280.179 158.609Z"
        fill="#F1F1F1"
      />
      <path
        d="M279.757 167.541C279.709 167.608 279.66 167.675 279.61 167.741H275.384C275.416 167.684 275.454 167.617 275.497 167.541C276.195 166.281 278.261 162.514 280.179 158.609C282.239 154.412 284.131 150.057 283.972 148.48C284.021 148.836 285.447 159.675 279.757 167.541Z"
        fill="#F1F1F1"
      />
      <path
        d="M16.0887 160.959C16.149 163.212 16.5264 165.445 17.2098 167.592C17.2249 167.642 17.2417 167.691 17.2581 167.741H21.4416C21.4372 167.696 21.4326 167.646 21.4282 167.592C21.1493 164.386 22.7428 145.101 24.6657 141.764C24.4972 142.034 15.7643 150.769 16.0887 160.959Z"
        fill="#F1F1F1"
      />
      <path
        d="M16.4027 167.592C16.4377 167.642 16.474 167.692 16.5113 167.741H19.6498C19.6261 167.699 19.5985 167.649 19.5665 167.592C19.0481 166.657 17.5135 163.859 16.0891 160.959C14.5589 157.842 13.1538 154.607 13.2722 153.436C13.2355 153.7 12.1762 161.75 16.4027 167.592Z"
        fill="#F1F1F1"
      />
      <path
        d="M220.471 118.133C188.874 104.743 160.289 105.252 133.363 113.425C91.8771 106.18 63.469 107.301 45.2468 118.133V84.5011H220.471V118.133Z"
        fill="#CBCBCB"
      />
      <path
        d="M220.471 108.885C191.361 95.6948 162.287 94.8433 132.775 108.885C102.547 100.181 73.325 100.272 45.0786 108.885V6.64238C75.1102 -2.32094 104.323 -2.10555 132.775 6.64238C162.829 -2.07533 192.087 -2.35182 220.471 6.64238V108.885Z"
        fill="#E5E5E5"
      />
      <path
        d="M52.9671 19.3305L51.9879 16.8243C52.3717 16.6744 61.542 13.1292 75.0003 11.3208C87.443 9.64856 105.981 9.24935 123.637 16.8414L122.574 19.3133C105.508 11.9752 87.5187 12.3616 75.4319 13.9776C62.276 15.7369 53.0587 19.2947 52.9671 19.3305Z"
        fill="white"
      />
      <path
        d="M52.9671 28.7475L51.9879 26.2414C52.3717 26.0914 61.542 22.5462 75.0003 20.7379C87.443 19.0658 105.981 18.6665 123.637 26.2584L122.574 28.7303C105.508 21.3923 87.5187 21.7784 75.4319 23.3946C62.276 25.1539 53.0587 28.7117 52.9671 28.7475Z"
        fill="white"
      />
      <path
        d="M52.9671 38.1646L51.9879 35.6584C52.3717 35.5085 61.542 31.9633 75.0003 30.1549C87.443 28.4827 105.981 28.0834 123.637 35.6755L122.574 38.1473C105.508 30.8093 87.5187 31.1954 75.4319 32.8117C62.276 34.571 53.0587 38.1288 52.9671 38.1646Z"
        fill="white"
      />
      <path
        d="M52.9671 47.5817L51.9879 45.0755C52.3717 44.9256 61.542 41.3804 75.0003 39.572C87.443 37.9001 105.981 37.5003 123.637 45.0926L122.574 47.5644C105.508 40.2264 87.5187 40.6127 75.4319 42.2287C62.276 43.988 53.0587 47.5459 52.9671 47.5817Z"
        fill="white"
      />
      <path
        d="M52.9671 56.9987L51.9879 54.4925C52.3717 54.3426 61.542 50.7974 75.0003 48.989C87.443 47.3167 105.981 46.9175 123.637 54.5096L122.574 56.9814C105.508 49.6432 87.5187 50.0297 75.4319 51.6458C62.276 53.405 53.0587 56.9629 52.9671 56.9987Z"
        fill="white"
      />
      <path
        d="M52.9671 66.4157L51.9879 63.9096C52.3717 63.7596 61.542 60.2145 75.0003 58.4061C87.443 56.7341 105.981 56.3346 123.637 63.9267L122.574 66.3985C105.508 59.0603 87.5187 59.4469 75.4319 61.0628C62.276 62.8221 53.0587 66.3799 52.9671 66.4157Z"
        fill="white"
      />
      <path
        d="M140.747 86.2588L139.768 83.7526C140.152 83.6027 149.322 80.0575 162.781 78.2491C175.223 76.5772 193.761 76.1776 211.417 83.7697L210.354 86.2415C193.289 78.9034 175.299 79.2899 163.212 80.9059C150.056 82.6652 140.839 86.223 140.747 86.2588Z"
        fill="#6B33F9"
      />
      <path
        d="M139.922 68.1857V16.7519L140.866 16.457C164.793 8.98281 188.604 8.9843 211.638 16.4614L212.567 16.7632V68.1172L210.747 67.4301C186.778 58.3835 163.574 58.3781 141.782 67.4142L139.922 68.1857ZM175.425 57.9446C186.687 57.9446 198.171 60.0438 209.877 64.2423V18.723C187.977 11.8168 165.356 11.8205 142.612 18.734V64.1798C153.062 60.0628 164.193 57.9476 175.425 57.9446Z"
        fill="white"
      />
      <path
        d="M209.933 84.9261L210.285 76.3663L222.375 52.3295C222.716 51.6462 223.271 51.0936 223.957 50.7567C224.376 50.5473 224.834 50.4237 225.302 50.3932C225.77 50.3626 226.239 50.4257 226.682 50.5787C227.196 50.7558 227.662 51.0491 228.043 51.4357C228.425 51.8223 228.712 52.2919 228.882 52.8077C228.955 53.0304 229.006 53.2597 229.035 53.4922C229.121 54.1825 228.999 54.8826 228.684 55.503L216.578 79.5724L209.933 84.9261Z"
        fill="#6B33F9"
      />
      <path
        d="M225.462 50.5544L212.339 76.6464L211.339 76.1431L224.031 50.9075C224.476 50.6848 224.965 50.5642 225.462 50.5544Z"
        fill="#3F3D56"
      />
      <path
        d="M228.868 53.5135L216.245 78.6109L215.244 78.1077L228.34 52.0699C228.502 52.3156 228.63 52.5813 228.722 52.8604C228.792 53.0729 228.84 53.2916 228.868 53.5135Z"
        fill="#3F3D56"
      />
      <path
        d="M227.611 51.274L214.343 77.6543L213.343 77.1511L226.627 50.7377C226.769 50.7861 226.907 50.8442 227.041 50.9116C227.242 51.0133 227.434 51.1347 227.611 51.274Z"
        fill="#3F3D56"
      />
      <path d="M211.45 83.4879L210.115 84.5631L210.227 81.7282C210.686 81.8872 211.182 82.5773 211.45 83.4879Z" fill="#3F3D56" />
      <path
        d="M79.7627 114.385L78.1643 109.536L79.8671 93.5944C79.9139 93.1417 80.1081 92.717 80.4197 92.3853C80.6098 92.1806 80.839 92.0158 81.0936 91.9006C81.3482 91.7854 81.6233 91.7221 81.9026 91.7145C82.2262 91.7056 82.5474 91.7715 82.8413 91.9072C83.1352 92.0428 83.3937 92.2446 83.5968 92.4966C83.6842 92.6055 83.7609 92.7226 83.8258 92.8463C84.0184 93.2132 84.0972 93.6294 84.0519 94.0413L82.3468 110.005L79.7627 114.385Z"
        fill="#6B33F9"
      />
      <path
        d="M81.2171 91.9569L79.3687 109.262L78.7051 109.191L80.4928 92.4539C80.6942 92.2365 80.9417 92.0666 81.2171 91.9569Z"
        fill="#3F3D56"
      />
      <path
        d="M83.7373 92.8932L81.9594 109.539L81.2958 109.468L83.1403 92.1986C83.2817 92.3018 83.409 92.4232 83.5189 92.5595C83.6023 92.6634 83.6754 92.7752 83.7373 92.8932Z"
        fill="#3F3D56"
      />
      <path
        d="M82.5666 91.9076L80.6978 109.404L80.0342 109.333L81.9053 91.8148C81.9945 91.8121 82.0836 91.8155 82.1723 91.8251C82.3061 91.8396 82.4382 91.8672 82.5666 91.9076Z"
        fill="#3F3D56"
      />
      <path d="M80.3076 113.264L79.7884 114.144L79.2563 112.539C79.5454 112.532 79.9669 112.813 80.3076 113.264Z" fill="#3F3D56" />
      <path
        d="M252.89 108.699C252.708 109.018 252.465 109.297 252.175 109.522C251.885 109.746 251.553 109.911 251.2 110.008C250.485 110.203 249.722 110.105 249.079 109.738C248.436 109.37 247.965 108.762 247.77 108.047C247.575 107.332 247.673 106.569 248.04 105.926C248.047 105.915 248.053 105.905 248.059 105.894C248.137 105.763 248.225 105.638 248.322 105.52L245.973 88.773L245.902 88.2713L245.425 84.8539L245.355 84.3613L245.615 84.3735L250.167 84.5816L251.388 84.6367L251.541 87.7542L252.407 105.282C252.859 105.715 253.153 106.287 253.24 106.907C253.328 107.527 253.205 108.158 252.89 108.699H252.89Z"
        fill="#FFB8B8"
      />
      <path
        d="M245.916 88.423L251.697 87.8928C251.996 87.8643 252.273 87.7186 252.466 87.4872C252.658 87.2559 252.752 86.9577 252.726 86.6577L251.655 74.6365C251.552 73.4787 250.993 72.4093 250.101 71.6637C249.209 70.9181 248.057 70.5573 246.9 70.6606C245.742 70.764 244.672 71.3231 243.927 72.2149C243.181 73.1067 242.82 74.2581 242.924 75.416C242.931 75.4988 242.941 75.5813 242.953 75.6635L244.686 87.4546C244.726 87.7248 244.862 87.9717 245.068 88.1503C245.275 88.3289 245.539 88.4274 245.812 88.4279C245.846 88.4285 245.881 88.4268 245.916 88.423Z"
        fill="#CBCBCB"
      />
      <path
        d="M250.298 78.7114C250.882 77.0552 250.909 75.2533 250.375 73.5803C249.84 71.9074 248.773 70.4551 247.336 69.4449C245.156 67.9534 242.173 67.418 238.961 71.2423C235.074 75.9111 232.301 81.4041 230.854 87.3045L244.009 89.4461C244.009 89.4461 248.483 83.9773 250.298 78.7114Z"
        fill="#CBCBCB"
      />
      <path d="M229.02 164.867H225.269L223.485 150.4H229.021L229.02 164.867Z" fill="#FFB8B8" />
      <path
        d="M218.036 168.35H229.825V163.795H222.591C221.383 163.795 220.224 164.275 219.37 165.129C218.516 165.983 218.036 167.142 218.036 168.35Z"
        fill="#2F2E41"
      />
      <path d="M259.857 161.596L256.296 162.773L250.061 149.597L255.317 147.859L259.857 161.596Z" fill="#FFB8B8" />
      <path
        d="M250.521 168.35L261.714 164.65L260.284 160.326L253.416 162.596C252.269 162.975 251.32 163.794 250.777 164.873C250.234 165.952 250.142 167.203 250.521 168.35Z"
        fill="#2F2E41"
      />
      <path
        d="M242.039 65.6515C246.189 65.6515 249.553 62.2873 249.553 58.1372C249.553 53.9872 246.189 50.6229 242.039 50.6229C237.889 50.6229 234.525 53.9872 234.525 58.1372C234.525 62.2873 237.889 65.6515 242.039 65.6515Z"
        fill="#FFB8B8"
      />
      <path
        d="M235.46 160.84L238.943 137.85C238.98 137.605 239.101 137.38 239.286 137.214C239.471 137.048 239.707 136.951 239.955 136.941C240.203 136.93 240.447 137.005 240.645 137.154C240.844 137.304 240.984 137.517 241.042 137.758L246.347 159.667C246.43 160.003 246.636 160.295 246.924 160.487C247.212 160.679 247.562 160.756 247.904 160.703L262.535 158.352C262.729 158.32 262.914 158.248 263.078 158.14C263.242 158.031 263.381 157.888 263.485 157.722C263.589 157.555 263.656 157.368 263.682 157.173C263.708 156.979 263.692 156.78 263.635 156.592L244.556 93.6902C244.501 93.5114 244.495 93.3215 244.537 93.1395L245.172 90.386C245.242 90.0875 245.21 89.7742 245.081 89.4962C244.952 89.2182 244.733 88.9913 244.46 88.8519C241.862 87.5263 235.282 84.7703 230.316 87.7891C230.155 87.8874 230.015 88.0175 229.906 88.1715C229.797 88.3254 229.72 88.5 229.68 88.6846L214.776 158.737C214.735 158.927 214.735 159.124 214.776 159.315C214.818 159.505 214.899 159.684 215.014 159.841C215.13 159.998 215.278 160.128 215.447 160.224C215.617 160.319 215.805 160.377 215.999 160.395L233.976 162.005C234.016 162.008 234.057 162.01 234.098 162.01C234.427 162.01 234.745 161.891 234.995 161.677C235.244 161.462 235.409 161.165 235.459 160.84L235.46 160.84Z"
        fill="#2F2E41"
      />
      <path
        d="M247.022 52.8282C246.455 52.4875 245.953 52.0505 245.536 51.5371C245.331 51.2777 245.196 50.9697 245.144 50.6428C245.092 50.316 245.125 49.9812 245.24 49.6708C245.619 48.8332 246.747 48.5694 247.622 48.8537C248.496 49.1379 249.171 49.8241 249.81 50.4853C250.373 51.0666 250.952 51.676 251.217 52.4406C251.483 53.2051 251.347 54.1746 250.683 54.6388C250.032 55.094 249.095 54.9077 248.468 54.4188C247.877 53.8842 247.405 53.2305 247.084 52.5005L247.022 52.8282Z"
        fill="#2F2E41"
      />
      <path
        d="M249.567 50.6871C249.609 48.57 252.022 47.0516 254.106 47.427C256.191 47.8024 257.793 49.5793 258.594 51.5392C260.289 55.6802 259.017 60.4052 257.384 64.5725C255.752 68.7398 253.731 72.9673 253.97 77.4353C254.131 79.8503 254.941 82.1768 256.315 84.1693C257.689 86.1618 259.576 87.7462 261.776 88.7553C259.424 89.8215 256.612 88.7201 254.635 87.0579C250.246 83.3667 248.551 76.7947 250.609 71.4412C251.624 68.8006 253.412 66.5415 254.809 64.0814C256.205 61.6213 257.23 58.7219 256.508 55.9864C255.787 53.251 252.719 51.0365 250.096 52.0948L249.567 50.6871Z"
        fill="#2F2E41"
      />
      <path
        d="M241.589 61.4718C242.244 60.3648 242.904 58.9116 241.707 57.9439C241.447 57.7431 241.149 57.5963 240.831 57.5123C240.513 57.4282 240.182 57.4085 239.856 57.4544C238.506 57.6172 237.036 57.6842 235.73 57.1937C235.24 57.0251 234.788 56.7597 234.403 56.4133C234.017 56.0669 233.706 55.6466 233.486 55.1772C232.813 53.579 233.656 51.6369 235.066 50.6266C235.929 50.031 236.948 49.7034 237.996 49.6849C239.044 49.6663 240.074 49.9576 240.958 50.5223C242.784 49.7155 245 50.0744 246.66 51.185C248.319 52.2955 249.454 54.074 250.071 55.973C250.639 57.6076 250.737 59.3688 250.354 61.0563C250.045 62.2302 249.458 63.3124 248.642 64.2113C247.826 65.1102 246.806 65.7993 245.668 66.2203C245.224 66.3952 244.743 66.4505 244.271 66.3807C243.8 66.3109 243.355 66.1185 242.981 65.8226C242.373 65.2778 241.907 64.594 241.621 63.8297C241.372 62.9923 241.239 62.0631 241.589 61.4718Z"
        fill="#2F2E41"
      />
      <path
        d="M219.297 68.1768C219.334 68.3418 219.359 68.5096 219.37 68.6785L232.512 76.2605L235.707 74.4214L239.112 78.8796L233.773 82.685C233.342 82.9925 232.823 83.1518 232.293 83.1389C231.763 83.126 231.252 82.9416 230.836 82.6134L217.283 71.9218C216.671 72.1485 216.005 72.1834 215.373 72.0221C214.741 71.8608 214.173 71.5108 213.744 71.0187C213.316 70.5266 213.047 69.9158 212.975 69.2674C212.902 68.6191 213.028 67.9639 213.337 67.3892C213.646 66.8145 214.122 66.3474 214.703 66.05C215.284 65.7527 215.941 65.6392 216.588 65.7247C217.235 65.8102 217.84 66.0907 218.324 66.5287C218.807 66.9667 219.146 67.5415 219.295 68.1768L219.297 68.1768Z"
        fill="#FFB8B8"
      />
      <path
        d="M232.952 76.6196L237.2 82.1248C237.321 82.2812 237.474 82.4098 237.649 82.5018C237.824 82.5938 238.016 82.647 238.214 82.6578C238.411 82.6686 238.609 82.6368 238.793 82.5645C238.977 82.4922 239.143 82.3812 239.28 82.2389L244.089 77.2456C244.493 76.9463 244.835 76.5702 245.093 76.1388C245.352 75.7074 245.524 75.2291 245.597 74.7314C245.671 74.2337 245.646 73.7263 245.523 73.2384C245.4 72.7505 245.182 72.2915 244.882 71.8879C244.582 71.4843 244.205 71.1439 243.772 70.8863C243.34 70.6286 242.862 70.4588 242.364 70.3865C241.866 70.3142 241.359 70.3409 240.871 70.465C240.383 70.5891 239.925 70.8081 239.522 71.1097C239.481 71.1406 239.44 71.1724 239.399 71.205L233.369 74.5771C233.196 74.6737 233.046 74.8064 232.93 74.9662C232.813 75.1259 232.732 75.3089 232.692 75.5027C232.653 75.6966 232.655 75.8967 232.7 76.0894C232.745 76.2821 232.83 76.463 232.951 76.6196H232.952Z"
        fill="#CBCBCB"
      />
      <path d="M70.6408 164.398L74.7181 164.397L76.658 148.671L70.6401 148.671L70.6408 164.398Z" fill="#A0616A" />
      <path
        d="M69.6007 163.066L77.6305 163.066H77.6308C78.9879 163.066 80.2895 163.605 81.2491 164.565C82.2087 165.524 82.7479 166.826 82.748 168.183V168.349L69.601 168.35L69.6007 163.066Z"
        fill="#2F2E41"
      />
      <path d="M38.8933 162.633L42.9248 163.242L47.1938 147.982L41.9084 147.082L38.8933 162.633Z" fill="#A0616A" />
      <path
        d="M38.0638 161.161L46.0034 162.361L46.0037 162.361C47.3455 162.564 48.5518 163.292 49.3573 164.384C50.1627 165.476 50.5012 166.844 50.2984 168.186L50.2736 168.35L37.2742 166.385L38.0638 161.161Z"
        fill="#2F2E41"
      />
      <path
        d="M51.4648 86.4586L55.992 87.6447L55.6908 94.9845L55.6535 108.348C56.0995 108.657 56.4413 109.093 56.6338 109.6C56.8263 110.107 56.8603 110.661 56.7314 111.187C56.6024 111.714 56.3166 112.189 55.9118 112.55C55.5069 112.911 55.002 113.14 54.4639 113.208C53.9259 113.275 53.3799 113.178 52.8984 112.929C52.4168 112.679 52.0224 112.29 51.7671 111.811C51.5119 111.333 51.4079 110.788 51.469 110.249C51.53 109.71 51.7532 109.203 52.1091 108.793L50.7179 95.3809L51.4648 86.4586Z"
        fill="#A0616A"
      />
      <path
        d="M50.6767 78.0515C50.7017 77.3422 50.9304 76.655 51.3356 76.0722C51.7407 75.4894 52.3051 75.0356 52.9612 74.765C53.5755 74.4902 54.2566 74.4007 54.921 74.5076C55.5854 74.6144 56.2041 74.913 56.7012 75.3665C57.0585 75.6858 57.3418 76.0791 57.5315 76.5191C57.7212 76.9591 57.8125 77.4352 57.7993 77.9141C57.8112 82.2139 56.8405 88.4813 56.8307 88.5439L56.8152 88.6437L50.198 90.872L50.6767 78.0515Z"
        fill="#CBCBCB"
      />
      <path
        d="M62.5085 90.2571L72.6297 87.6372L77.3082 103.394L77.4745 162.094L69.7348 162.045L66.4995 114.535L45.8796 158.436L37.5652 157.438L55.6907 110.378C55.6907 110.378 54.693 102.064 57.8524 97.9064L57.1873 93.5828L62.5085 90.2571Z"
        fill="#2F2E41"
      />
      <path
        d="M55.684 94.1774C55.5425 94.0054 55.4397 93.8049 55.3826 93.5897C55.3256 93.3744 55.3155 93.1494 55.3532 92.9299L50.7365 79.0798C50.521 78.4324 50.4981 77.7363 50.6706 77.0761C50.8431 76.4159 51.2036 75.82 51.7084 75.3607L53.7125 73.539L55.0707 68.1064L62.8767 70.0145L62.2336 72.5871L65.7664 71.945L73.2075 88.518L57.6537 94.735L56.8877 94.747C56.6573 94.7474 56.4298 94.6965 56.2216 94.598C56.0134 94.4994 55.8297 94.3558 55.684 94.1774Z"
        fill="#CBCBCB"
      />
      <path
        d="M66.5187 82.4834L70.9788 81.0659L74.6477 87.4301L81.7594 98.7443C82.3012 98.7666 82.8235 98.9528 83.2572 99.2784C83.6909 99.604 84.0155 100.054 84.1881 100.568C84.3607 101.082 84.3732 101.636 84.2238 102.158C84.0745 102.679 83.7704 103.143 83.3518 103.487C82.9332 103.832 82.4198 104.042 81.8795 104.088C81.3392 104.135 80.7974 104.016 80.326 103.749C79.8545 103.481 79.4754 103.076 79.239 102.588C79.0026 102.1 78.9199 101.551 79.0019 101.015L70.6567 90.4233L66.5187 82.4834Z"
        fill="#A0616A"
      />
      <path
        d="M61.3588 75.7991C61.0007 75.1863 60.8268 74.4834 60.8576 73.7743C60.8885 73.0653 61.1229 72.3801 61.5328 71.8007C61.9052 71.2403 62.4331 70.8007 63.0516 70.5359C63.6702 70.2711 64.3526 70.1926 65.0152 70.31C65.4878 70.3888 65.9375 70.5698 66.333 70.8403C66.7284 71.1107 67.0602 71.4642 67.305 71.8761C69.6134 75.5036 72.1431 81.3195 72.1684 81.3776L72.2085 81.4702L67.8071 86.8905L61.3588 75.7991Z"
        fill="#CBCBCB"
      />
      <path
        d="M60.8461 67.0954C64.2715 67.0954 67.0483 64.3186 67.0483 60.8932C67.0483 57.4678 64.2715 54.691 60.8461 54.691C57.4207 54.691 54.6439 57.4678 54.6439 60.8932C54.6439 64.3186 57.4207 67.0954 60.8461 67.0954Z"
        fill="#A0616A"
      />
      <path
        d="M49.8854 58.2771C53.185 58.2771 55.8597 55.6023 55.8597 52.3028C55.8597 49.0032 53.185 46.3285 49.8854 46.3285C46.5859 46.3285 43.9111 49.0032 43.9111 52.3028C43.9111 55.6023 46.5859 58.2771 49.8854 58.2771Z"
        fill="#2F2E41"
      />
      <path
        d="M44.7323 57.2499C45.5056 57.8088 46.4014 58.1744 47.345 58.3161C48.2885 58.4577 49.2522 58.3714 50.1555 58.0642C51.0588 57.7571 51.8754 57.2381 52.537 56.5506C53.1986 55.8632 53.6859 55.0273 53.9582 54.1129C53.8552 54.9324 53.5834 55.7215 53.1599 56.4306C52.7365 57.1397 52.1706 57.7533 51.4981 58.2327C50.8256 58.7121 50.0609 59.0468 49.2525 59.2158C48.4441 59.3847 47.6094 59.3842 46.8011 59.2143C45.9929 59.0444 45.2287 58.7088 44.5567 58.2286C43.8847 57.7484 43.3196 57.1342 42.897 56.4246C42.4744 55.715 42.2035 54.9254 42.1015 54.1059C41.9994 53.2863 42.0684 52.4545 42.304 51.6629C42.1702 52.7264 42.325 53.8063 42.7523 54.7893C43.1795 55.7723 43.8635 56.6222 44.7323 57.2499Z"
        fill="#2F2E41"
      />
      <path
        d="M68.3095 57.2565C67.2666 55.3893 66.8675 54.6094 65.5227 53.4739C64.3335 52.4698 62.8708 52.1749 61.691 53.1263C60.2953 52.5233 58.7423 52.3866 57.2627 52.7364C55.7831 53.0863 54.4558 53.9041 53.478 55.0684C52.5002 56.2327 51.924 57.6813 51.8351 59.1991C51.7462 60.7169 52.1492 62.2229 52.9844 63.4935C53.8195 64.764 55.0422 65.7312 56.4708 66.2514C57.8995 66.7717 59.4578 66.8173 60.9144 66.3813C62.371 65.9454 63.6481 65.0513 64.556 63.8317C65.464 62.6122 65.9543 61.1323 65.9542 59.6119C65.9531 59.3449 65.937 59.0782 65.906 58.813C66.9316 58.669 67.284 57.4006 68.3095 57.2565Z"
        fill="#2F2E41"
      />
      <path
        d="M128.975 168.414H0.836323C0.747124 168.414 0.661579 168.378 0.598506 168.315C0.535434 168.252 0.5 168.167 0.5 168.077C0.5 167.988 0.535434 167.903 0.598506 167.84C0.661579 167.776 0.747124 167.741 0.836323 167.741H128.975C129.065 167.741 129.15 167.776 129.213 167.84C129.276 167.903 129.312 167.988 129.312 168.077C129.312 168.167 129.276 168.252 129.213 168.315C129.15 168.378 129.065 168.414 128.975 168.414Z"
        fill="#CBCBCB"
      />
      <path
        d="M300.164 168.414H172.025C171.935 168.414 171.85 168.378 171.787 168.315C171.724 168.252 171.688 168.167 171.688 168.077C171.688 167.988 171.724 167.903 171.787 167.84C171.85 167.776 171.935 167.741 172.025 167.741H300.164C300.253 167.741 300.338 167.776 300.402 167.84C300.465 167.903 300.5 167.988 300.5 168.077C300.5 168.167 300.465 168.252 300.402 168.315C300.338 168.378 300.253 168.414 300.164 168.414Z"
        fill="#CBCBCB"
      />
      <path d="M133.111 6.64235H132.438V108.561H133.111V6.64235Z" fill="#CBCBCB" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="300" height="168.414" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default DefaultImageFilledIcon;
