import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useGetQuiz } from '../../api/quizApi';
import AnsweringBody from './answering-body/answering-body';

const QuizLaunch = (): JSX.Element | null => {
  const { id } = useParams<{ id: string; resourceLinkId: string }>();
  const searchParams = new URLSearchParams(useLocation().search);
  const resourceLinkId = searchParams.get('resourceLinkId') || undefined;
  const { data: quiz } = useGetQuiz(id);

  return quiz ? <AnsweringBody quiz={quiz} resourceLinkId={resourceLinkId} /> : null;
};

export default QuizLaunch;
