import React, { createRef, FC, ReactNode, useEffect, useState } from 'react';
import './question-save-form.scss';
import { Button, Col, Divider, Form, FormInstance, Input, InputNumber, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { Question } from '../../../model/question.model';
import { createQuestion, updateQuestion } from '../../../api/questionApi';
import { Quiz } from '../../../model/quiz.model';
import { useQuestionCreationFormState, useQuizState, useSelectedQuestionTypeState } from '../../../shared/context/quiz-edit-context';
import { MULTIPLE_CHOICE_CHECKBOX, MULTIPLE_CHOICE_RADIO, SHORT_TEXT } from '../../../config/constants';
import times from 'lodash/times';
import { FormListFieldData } from 'antd/es/form/FormList';
import { StoreValue } from 'antd/lib/form/interface';
import CheckboxInputList from './checkbox-input-list';
import RadioInputList from './radio-input-list';
import TextInputList from './text-input-list';
import BaseQuestion from '../base-question';
import { getQuestionTypeDescriptionKey, getQuestionTypeKey } from '../../../shared/util/quiz-util';
import QuestionControls from '../../../shared/layout/quiz/question-controls';
import PreviewButton from '../../shared/preview-button';
import Preview from '../../shared/preview';
import MathJaxTutorialModal from '../../shared/math-jax-tutorial-modal';
import ImageUpload from '../../shared/image-upload';

export interface IQuestionSaveForm {
  existingQuestion?: Question;
  questionType: string;
  onClose?: () => void;
  sequenceNumber: number;
}

const QuestionSaveForm: FC<IQuestionSaveForm> = ({ existingQuestion, questionType, onClose, sequenceNumber }) => {
  const { TextArea } = Input;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { quiz, setQuiz } = useQuizState();
  const points = existingQuestion?.points ? existingQuestion.points : 1;
  const formRef = createRef<FormInstance>();
  const { setQuestionCreationForm } = useQuestionCreationFormState();
  const { setSelectedQuestionType } = useSelectedQuestionTypeState();

  const emptyChoice = {
    id: undefined,
    choiceString: '',
    correct: questionType === SHORT_TEXT,
  };

  const createEmptyChoices = () => {
    return times(3, () => emptyChoice);
  };

  const onFormClose = () => {
    if (onClose) {
      onClose();
    }
    setQuestionCreationForm(() => null);
    setSelectedQuestionType('');
  };

  const onFinish = () => {
    form
      .validateFields()
      .then((values: Question) => {
        const questionData: Question = {
          ...existingQuestion,
          ...values,
          quizId: quiz?.id,
          questionType,
        };
        existingQuestion
          ? updateQuestion(questionData)
              .then(res => {
                const updatedQuiz: Quiz | undefined = quiz && (Object.create(quiz) as Quiz);
                updatedQuiz?.questions?.splice(sequenceNumber - 1, 1, res.data);
                setQuiz(updatedQuiz);
                onFormClose();
              })
              .catch(() => console.error('Unable to update question'))
          : createQuestion(questionData)
              .then(res => {
                quiz?.questions?.push(res.data);
                setQuiz(quiz);
                onFormClose();
              })
              .catch(() => console.error('Unable to save question'));
      })
      .catch(() => console.error('Failed to validate form fields'));
  };

  const renderChoiceInputs = (
    choiceFields: FormListFieldData[],
    add: () => void,
    remove: (index: number | number[]) => void,
    errors: ReactNode[]
  ) => {
    if (questionType === MULTIPLE_CHOICE_RADIO) {
      return <RadioInputList choiceFields={choiceFields} add={add} remove={remove} errors={errors} formRef={formRef} form={form} />;
    } else if (questionType === MULTIPLE_CHOICE_CHECKBOX) {
      return <CheckboxInputList choiceFields={choiceFields} add={add} remove={remove} errors={errors} />;
    } else if (questionType === SHORT_TEXT) {
      return <TextInputList textFields={choiceFields} add={add} remove={remove} errors={errors} />;
    }
  };

  const QuestionBody = () => (
    <Row>
      <Col xs={24} md={12}>
        <div className={'choices-container'}>
          <Divider orientation="left" className={'divider-color'}>
            {t('quiz.update.questionForm.answerOptions')}
          </Divider>
          <Form.List
            name={'choices'}
            initialValue={existingQuestion?.choices || createEmptyChoices()}
            rules={[
              {
                async validator(_, choices) {
                  if (!choices || choices?.filter((choice: StoreValue) => choice?.correct === true)?.length < 1) {
                    return Promise.reject(new Error(t('quiz.update.questionForm.atLeastOneAnswerMustBeCorrect')));
                  }
                },
              },
            ]}
          >
            {(choices, { add, remove }, { errors }) => renderChoiceInputs(choices, () => add(emptyChoice), remove, errors)}
          </Form.List>
        </div>
      </Col>
      <Col xs={24} md={12} className={'icons-and-image-col'}>
        <Row>
          <Col xs={24}>
            <div className={'settings-container'}>
              <Divider orientation="left" className={'divider-color'}>
                {t('quiz.update.questionForm.settings')}
              </Divider>
              <Row>
                <Col xs={24} xxl={6} className={'mb-05'}>
                  <Form.Item
                    label={t('quiz.update.questionForm.points')}
                    rules={[
                      {
                        async validator(_, pointsValue) {
                          if (pointsValue === null || pointsValue <= 0) {
                            return Promise.reject(new Error(t('quiz.update.questionForm.hasToBeHigherThan0')));
                          }
                        },
                      },
                    ]}
                    initialValue={points?.toString()}
                    name={'points'}
                  >
                    <InputNumber
                      onChange={e => {
                        form.setFieldsValue({
                          points: e >= 0 ? e : 0,
                        });
                      }}
                      className={'border-radius-0'}
                    />
                  </Form.Item>
                </Col>
                {/* Not implemented yet */}
                {/* {questionType !== SHORT_TEXT && ( */}
                {/*  <Col xs={24} xxl={11} className={'mb-05'}> */}
                {/*    <p>{t('quiz.update.questionForm.numberOfOptionsDisplayed')}</p> */}
                {/*    <Form.Item */}
                {/*      rules={[ */}
                {/*        { */}
                {/*          required: true, */}
                {/*          message: `${t('quiz.update.questionForm.required')}`, */}
                {/*        }, */}
                {/*      ]} */}
                {/*      initialValue={0} */}
                {/*      name={'visibleAnswerOptions'} */}
                {/*    > */}
                {/*      <InputNumber */}
                {/*        onChange={e => { */}
                {/*          form.setFieldsValue({ */}
                {/*            visibleAnswerOptions: e >= 0 ? e : 0, */}
                {/*          }); */}
                {/*        }} */}
                {/*        className={'border-radius-0'} */}
                {/*      /> */}
                {/*    </Form.Item> */}
                {/*  </Col> */}
                {/* )} */}
                {/* <Col xs={24} xxl={7} className={'mb-05'}> */}
                {/*  <p>{t('quiz.update.questionForm.answerRequiredToggle')}</p> */}
                {/*  <Switch /> */}
                {/* </Col> */}
              </Row>
              <Divider orientation="left" className={'divider-color'}>
                {t('quiz.update.questionForm.picture')}
              </Divider>
              <Row>
                <Col xs={24}>
                  <Form.Item name={'image'} initialValue={existingQuestion?.image}>
                    <ImageUpload
                      type={'dragdrop'}
                      uniqueImageName={existingQuestion?.image}
                      setUniqueImageName={image => form.setFieldsValue({ image })}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );

  const QuestionWithMathJaxPreview = () => {
    const [preview, setPreview] = useState<boolean>(false);
    const [previewValue, setPreviewValue] = useState<string>();

    useEffect(() => {
      setPreviewValue(existingQuestion?.questionString);
    }, [existingQuestion?.questionString]);

    return (
      <Col xs={{ span: 18, push: 1 }} md={{ span: 24, push: 0 }} className={'question-input horizontal-container'}>
        <p>
          <span className={'color-danger'}>*</span> {t('quiz.update.questionForm.question')}
        </p>
        <Space size={4} className={'textarea-buttons'}>
          <PreviewButton className={'textarea-button'} setPreview={setPreview} preview={preview} />
          <MathJaxTutorialModal />
        </Space>
        <Form.Item
          rules={[
            {
              required: true,
              message: `${t('quiz.update.questionForm.questionRequired')}`,
            },
          ]}
          initialValue={existingQuestion?.questionString}
          name={'questionString'}
        >
          <TextArea
            data-testid={'question-textbox'}
            size={'large'}
            placeholder={t('quiz.update.questionForm.enterQuestion')}
            className={'border-radius-0'}
            onChange={e => setPreviewValue(e.target.value)}
          />
        </Form.Item>
        <Preview preview={preview} value={previewValue} />
      </Col>
    );
  };

  return (
    <Form
      layout={'vertical'}
      className={'quizzeria-question-container question-save-form'}
      data-testid={'question-save-form'}
      form={form}
      onFinish={onFinish}
      ref={formRef}
    >
      <BaseQuestion sequenceNumber={sequenceNumber} className={'question-row'}>
        <div className={'question-info'}>
          <p className={'question-type'}>{t(getQuestionTypeKey(questionType))}</p>
          <p className={'question-hint'}>{t(getQuestionTypeDescriptionKey(questionType))}</p>
        </div>
        <QuestionControls question={existingQuestion} questionSequenceNumber={sequenceNumber} />
        <QuestionWithMathJaxPreview />
        <Col xs={24}>
          <QuestionBody />
        </Col>
        <Col xs={24}>
          <Space className={'float-right horizontal-container'}>
            <Button type="primary" htmlType="submit" className={'border-radius-0'}>
              {t('quiz.update.questionForm.save')}
            </Button>
            <Button className={'border-radius-0'} onClick={onFormClose}>
              {t('quiz.update.questionForm.cancelChanges')}
            </Button>
          </Space>
        </Col>
      </BaseQuestion>
    </Form>
  );
};

export default QuestionSaveForm;
