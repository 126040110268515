import React, { FC } from 'react';
import { IconInterface } from './index';

const UpIcon: FC<IconInterface> = ({
  style = {},
  width = '14px',
  height = '9px',
  viewBox = '0 0 14 9',
  className = '',
  fill = 'none',
  stroke = '#8C8C8C',
  ariaLabel = '',
}) => (
  <svg
    aria-label={ariaLabel}
    className={className}
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.16666 7.50094L6.99999 1.6676L12.8333 7.50094"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UpIcon;
