import React, { createRef, FC, useEffect, useState } from 'react';
import { Button, Col, Form, FormInstance, Layout, Popconfirm, Row, Space, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { Quiz } from '../../../model/quiz.model';
import QuizDetail from '../../shared/quiz-detail';
import QuestionBox from '../../update-quiz/question-box';
import { Submission } from '../../../model/submission.model';
import { getUserSubmission, useCreateSubmission } from '../../../api/submissionApi';
import { Answer } from '../../../model/answer.model';
import { Question } from '../../../model/question.model';
import { calculateQuizMaxPoints, getAuthorFullName } from '../../../shared/util/quiz-util';
import { useHistory } from 'react-router-dom';
import Header from '../../../shared/layout/header/header';
import { convertServerTimeToShortDate } from '../../../shared/util/date-utils';
import './answering-body.scss';
import { hasAnyAuthority } from '../../../shared/auth/private-route';
import { ADMIN, INSTRUCTOR, TEACHING_ASSISTANT } from '../../../shared/constants';
import { useAccountState } from '../../../shared/context/root-context';
import { LoadingOutlined } from '@ant-design/icons';

interface IProps {
  quiz: Quiz;
  resourceLinkId?: string;
}

const AnsweringBody: FC<IProps> = ({ quiz, resourceLinkId }: IProps) => {
  const { Content } = Layout;
  const { t } = useTranslation();
  const { account } = useAccountState();
  const [submission, setSubmission] = useState<Submission | null>(null);
  const [form] = Form.useForm();
  const formRef = createRef<FormInstance>();
  const history = useHistory();
  const studentId: string | null = new URLSearchParams(history.location.search).get('studentId');
  const onSubmissionCreationSuccess = () => {
    getUserSubmission()
      .then(res => setSubmission(res))
      .catch(() => console.error('Could not fetch submission'));
  };
  const { mutate: createSubmission, isLoading: isCreatingSubmissionInProgress } = useCreateSubmission(onSubmissionCreationSuccess);

  useEffect(() => {
    getUserSubmission(studentId)
      .then(res => setSubmission(res))
      .catch(() => console.error('Could not fetch submission'));
  }, []);

  const findAnswerForQuestion = (question: Question): Answer | undefined => {
    return submission?.answers?.find(answer => answer.questionId === question.id);
  };

  const renderAnswerBoxes = () =>
    quiz.questions?.map((question, i) => (
      <QuestionBox
        key={i}
        question={question}
        questionType={question.questionType}
        inForm={!submission?.id}
        sequenceNumber={i + 1}
        formRef={formRef}
        answer={findAnswerForQuestion(question)}
      />
    ));

  const onFinish = () => {
    form
      .validateFields()
      .then((values: { answers: Answer[] }) => {
        const answers: Answer[] = [];
        values?.answers?.forEach(answer => {
          const choices = answer?.choices?.filter(choice => choice.correct);
          if (choices || answer?.textAnswer?.length) {
            const filteredAnswer: Answer = {
              ...answer,
              choices,
            };
            answers.push(filteredAnswer);
          }
        });
        const submissionData: Submission = {
          quizId: quiz?.id,
          answers,
          resourceLinkId,
        };
        createSubmission(submissionData);
      })
      .catch(err => console.error(err));
  };

  const MenuRightComponent = () => (
    <Space align={'center'}>
      <span>{`${t('quiz.update.lastSaved')}: ${quiz?.createdDate ? convertServerTimeToShortDate(quiz.createdDate) : ''}`}</span>
      <div>{getAuthorFullName(quiz)}</div>
    </Space>
  );

  const isCurrentUserInstructorOrAdmin = (): boolean => {
    const authorities = account ? account.authorities : [];
    return hasAnyAuthority(authorities, [INSTRUCTOR, TEACHING_ASSISTANT, ADMIN]);
  };

  return submission !== null ? (
    <Content className={'answering-body'}>
      <Header rightComponent={isCurrentUserInstructorOrAdmin() ? <MenuRightComponent /> : <></>} />
      <Row className={'content'}>
        <Col className={'container-col'} xl={16} lg={20} xs={24}>
          <QuizDetail quiz={quiz} />
          <Form form={form} ref={formRef}>
            <Form.List
              name={'answers'}
              initialValue={quiz?.questions?.map(question => ({
                questionId: question.id,
              }))}
            >
              {renderAnswerBoxes}
            </Form.List>
            {!submission && !studentId ? (
              <div className={'quiz-button-container'}>
                <Space align={'center'} className={'quiz-button-container'} size={'small'}>
                  <Popconfirm
                    placement="top"
                    title={t('quiz.answeringBody.submitYourSolution')}
                    onConfirm={onFinish}
                    okText={t('global.actions.yes')}
                    cancelText={t('global.actions.no')}
                    disabled={isCreatingSubmissionInProgress}
                  >
                    <Button type={'primary'} size={'large'} htmlType={'submit'} disabled={isCreatingSubmissionInProgress}>
                      {isCreatingSubmissionInProgress ? (
                        <>
                          <Spin indicator={<LoadingOutlined spin />} /> {t('global.actions.submitInProgress')}
                        </>
                      ) : (
                        t('global.actions.submit')
                      )}
                    </Button>
                  </Popconfirm>
                </Space>
              </div>
            ) : null}
          </Form>
          {submission?.scoreGiven !== undefined && (
            <Row className={'quiz-footer'}>
              <Col>
                <h2>{t('quiz.answeringBody.totalScore')}</h2>
              </Col>
              <Col xs={24} className={'score-total-container'}>
                <div className={'item score-total'}>
                  <span>{`${submission?.scoreGiven ? +submission.scoreGiven : 0} / ${calculateQuizMaxPoints(quiz.questions)} ${t(
                    'quiz.points-abbr'
                  )}`}</span>
                </div>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Content>
  ) : null;
};

export default AnsweringBody;
