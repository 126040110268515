import React, { FC } from 'react';
import Icon from '../../icons';
import { Question } from '../../../model/question.model';
import { Dropdown, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQuizState } from '../../context/quiz-edit-context';
import { deleteQuestion } from '../../../api/questionApi';
import { Quiz } from '../../../model/quiz.model';

import './question-controls.scss';

interface QuestionControlsProps {
  question?: Question;
  questionSequenceNumber: number;
}

const QuestionControls: FC<QuestionControlsProps> = ({ question, questionSequenceNumber }) => {
  const { quiz, setQuiz } = useQuizState();
  const { t } = useTranslation();

  const removeQuestion = () => {
    question?.id &&
      deleteQuestion(question.id).then(() => {
        const updatedQuiz: Quiz | undefined = quiz && (Object.create(quiz) as Quiz);
        updatedQuiz?.questions?.splice(questionSequenceNumber - 1, 1);
        setQuiz(updatedQuiz);
      });
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={() => removeQuestion()} key="1">
        {t('quiz.update.delete')}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={'question-controls'} data-testid={'question-controls'}>
      {/* Not implemented yet */}
      {/* <Icon name={'down'} className={'down-icon'} ariaLabel={t('quiz.update.moveDown')} /> */}
      {/* <Icon name={'up'} className={'up-icon'} ariaLabel={t('quiz.update.moveUp')} /> */}
      {question?.id && (
        <Dropdown overlay={menu} trigger={['click']} placement={'topCenter'}>
          <a aria-label={t('global.quizCard.dropdown')}>
            <Icon name={'dropdown'} className={'dropdown-icon'} />
          </a>
        </Dropdown>
      )}
    </div>
  );
};

export default QuestionControls;
