import React, { FC } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useAccountState } from "../context/root-context";
import { useTranslation } from "react-i18next";

interface IProps extends Omit<RouteProps, "component"> {
    component: React.ElementType
    authorities?: string[];
}

const PrivateRoute: FC<IProps> = ({component: Component, authorities = [], ...rest }) => {
    const { account } = useAccountState();
    const {t} = useTranslation();

    const renderByAuthorities = (props: RouteProps) => {
        const isAuthorized = hasAnyAuthority(account ? account.authorities : [], authorities);

        return (isAuthorized ? (
            <Component {...props} />
        ) : (
            <div className="insufficient-authority">
                <div className="alert alert-danger">
                    <p>{t('error.403')}</p>
                </div>
            </div>
        ))
    }

    if (!Component) throw new Error(`A component needs to be specified for private route for path ${(rest as RouteProps).path?.toString() || ''}`);

    return <Route {...rest} render={account && renderByAuthorities} />;
};

export const hasAnyAuthority = (authorities: string[], hasAnyAuthorities: string[]): boolean => {
    if (authorities && authorities.length !== 0) {
        if (hasAnyAuthorities.length === 0) {
            return true;
        }
        return hasAnyAuthorities.some(auth => authorities.includes(auth));
    }
    return false;
};

export default PrivateRoute;
