import React, { useEffect, useState } from 'react';
import { Button, Col, Layout, Row, Space } from 'antd';
import './quiz-preview.scss';
import { useParams } from 'react-router-dom';
import { getQuiz } from '../../api/quizApi';
import { Quiz } from '../../model/quiz.model';
import SelectQuizButton from '../select/select-quiz-button';
import { useTranslation } from 'react-i18next';
import QuizDetail from '../shared/quiz-detail';
import QuestionBox from '../update-quiz/question-box';

const QuizPreview = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { Content } = Layout;
  const { id } = useParams<{ id: string }>();
  const [quiz, setQuiz] = useState<Quiz>();

  useEffect(() => {
    getQuiz(id)
      .then(res => setQuiz(res))
      .catch(() => console.error('Could not fetch a quiz'));
  }, []);

  const renderQuizSelection = () => {
    return quiz ? (
      <Content className={'quizzeria-preview quizzeria-content'}>
        <Row>
          <Col className={'container-col'} xl={16} lg={20} xs={24}>
            <QuizDetail quiz={quiz} />
            <div className={'deeplink-buttons-container'}>
              <Space>
                <Button onClick={() => history.back()}>{t('global.actions.back')}</Button>
                <SelectQuizButton quiz={quiz} />
              </Space>
            </div>
            {quiz.questions?.map((question, i) => (
              <QuestionBox key={i} question={question} questionType={question.questionType} sequenceNumber={i + 1} isPreview />
            ))}
          </Col>
        </Row>
      </Content>
    ) : null;
  };

  return quiz ? renderQuizSelection() : null;
};

export default QuizPreview;
