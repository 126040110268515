import React, { FC } from 'react';
import { IconInterface } from './index';

const UserIcon: FC<IconInterface> = ({
  name,
  style = {},
  width = '17px',
  height = '16px',
  viewBox = '0 0 17 16',
  className = '',
  fill = 'none',
  stroke = '#434343',
}) => (
  <svg
    aria-label={name}
    className={className}
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.75 9.75V14C15.75 14.5523 15.3023 15 14.75 15H2.75C2.19772 15 1.75 14.5523 1.75 14V2C1.75 1.44772 2.19772 1 2.75 1H9.625M15.75 1.77778L10.3264 8.55726C9.92046 9.0647 9.14593 9.05622 8.75119 8.54001L7 6.25"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default UserIcon;
