import React, { FC } from 'react';
import BaseQuestion from '../base-question';
import { Button, Col, Space, Tag } from 'antd';
import { CORRECT_COLOR, INCORRECT_COLOR, PARTIALLY_CORRECT_COLOR, PRIMARY_COLOR } from '../../../shared/style-constants';
import { Answer } from '../../../model/answer.model';
import { Question } from '../../../model/question.model';
import './base-question-view.scss';
import { useTranslation } from 'react-i18next';
import { getQuestionTypeKey, isTextBasedQuestion, textAnswerCorrect } from '../../../shared/util/quiz-util';
import QuestionControls from '../../../shared/layout/quiz/question-controls';
import { CORRECT, INCORRECT, PARTIALLY_CORRECT } from '../../../shared/constants';
import MathJax from 'react-mathjax-preview';

interface IProps {
  sequenceNumber: number;
  answerColorClass?: string;
  onEdit?: () => void;
  answer?: Answer;
  question: Question;
  choicesElement?: JSX.Element;
  correctChoicesElement?: JSX.Element;
  editable?: boolean;
  isPreview?: boolean;
  dataTestid?: string;
  className?: string;
}

const BaseQuestionView: FC<IProps> = ({
  sequenceNumber,
  answerColorClass = '',
  onEdit,
  answer,
  question,
  choicesElement,
  correctChoicesElement,
  editable,
  isPreview,
  dataTestid,
  className = '',
}: IProps) => {
  const { t } = useTranslation();
  const isSubmittedView = !editable && !isPreview;

  const everyAnswerChoiceCorrect = (): boolean => {
    return !!answer?.choices?.every(c => c.correct);
  };

  const everyCorrectChoiceChosen = (): boolean => {
    const chosenChoices = answer?.choices;
    const correctChoices = question?.choices?.filter(choice => choice.correct);
    return !!correctChoices?.every(correctChoice => chosenChoices?.some(chosenChoice => chosenChoice?.id === correctChoice?.id));
  };

  const choiceAnswerCorrect = (): boolean => {
    return everyAnswerChoiceCorrect() && everyCorrectChoiceChosen();
  };

  const answerCorrect = (): boolean => {
    return isTextBasedQuestion(question) ? !!answer?.textAnswer && textAnswerCorrect(answer.textAnswer, question) : choiceAnswerCorrect();
  };

  const getPointsColor = (): string => {
    if (answerColorClass === CORRECT) {
      return CORRECT_COLOR;
    } else if (answerColorClass === PARTIALLY_CORRECT) {
      return PARTIALLY_CORRECT_COLOR;
    } else if (answerColorClass === INCORRECT) {
      return INCORRECT_COLOR;
    }

    return PRIMARY_COLOR;
  };

  const pointsColor = getPointsColor();

  const getPointsTagIfNoAnswerPresent = (): JSX.Element =>
    !isSubmittedView ? (
      <Tag color={pointsColor}>{`${+question.points} ${t('quiz.points-abbr')}`}</Tag>
    ) : (
      <Tag color={pointsColor}>{`0 / ${+question.points} ${t('quiz.points-abbr')}`}</Tag>
    );

  const Points = (): JSX.Element =>
    answer ? (
      <Tag color={pointsColor}>{`${answer.score ? +answer.score : 0} / ${+question.points} ${t('quiz.points-abbr')}`}</Tag>
    ) : (
      getPointsTagIfNoAnswerPresent()
    );

  return (
    <BaseQuestion
      sequenceNumber={sequenceNumber}
      className={`base-question-view ${className}`}
      sequenceNumberClass={answerColorClass}
      dataTestid={dataTestid}
    >
      <>
        <Col xs={18} md={20} xxl={22} className={'input-col'}>
          <div className={'question-info'}>
            {!isSubmittedView && <div className={'question-type'}>{t(getQuestionTypeKey(question.questionType))}</div>}
            <Space align={'end'}>
              <div className={'question text-xl font-bold '}>
                <MathJax math={question.questionString} />
              </div>
              <div className={'points-container'}>
                <Points />
              </div>
            </Space>
            <Col sm={24} md={18} xl={15} xxl={12}>
              {question.image && <img className={'mt-1'} src={`/api/files/${question.image}`} alt={question.questionString} />}
            </Col>
          </div>
          {<div className={'choices text-base'}>{choicesElement}</div>}
          {!editable && !answerCorrect() && <>{correctChoicesElement}</>}
        </Col>
        <Col xs={3} md={2} xxl={1}>
          {editable && <QuestionControls question={question} questionSequenceNumber={sequenceNumber} />}
        </Col>
        {editable && (
          <Col className={'edit-button-col'} xs={24}>
            <Button onClick={() => onEdit && onEdit()}>{t('global.quizCard.edit')}</Button>
          </Col>
        )}
      </>
    </BaseQuestion>
  );
};

export default BaseQuestionView;
