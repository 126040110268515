import React, { FC, useState } from 'react';
import { Button, Col, Divider, Row, Space, Tag, Typography } from 'antd';
import './quiz-detail.scss';
import SaveQuizModal from '../save-quiz-modal/save-quiz-modal';
import { Quiz } from '../../model/quiz.model';
import { getQuiz } from '../../api/quizApi';
import { useParams } from 'react-router-dom';
import { calculateQuizMaxPoints } from '../../shared/util/quiz-util';
import Icon from '../../shared/icons';
import { PRIMARY_COLOR, SECONDARY_COLOR, SUBJECT_TAG_COLOR } from '../../shared/style-constants';
import { useTranslation } from 'react-i18next';
import { useAccountState } from '../../shared/context/root-context';

interface IProps {
  quiz: Quiz;
  onQuizChange?: (quiz: Quiz) => void;
  isAllowedToModify?: boolean;
}

const QuizDetail: FC<IProps> = ({ quiz, onQuizChange, isAllowedToModify }: IProps) => {
  const { Text } = Typography;
  const [saveQuizModalVisible, setSaveQuizModalVisible] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const { account } = useAccountState();
  const { t } = useTranslation();

  const subjects = () => {
    return quiz?.subjects?.map((subject, i) => (
      <Tag key={i} color={SUBJECT_TAG_COLOR} className={'subject-tag'}>
        {subject.targetName}
      </Tag>
    ));
  };

  const updateQuiz = () => {
    if (onQuizChange) {
      getQuiz(id)
        .then(res => onQuizChange(res))
        .catch(() => console.error('Could not fetch a quiz'));
    }
  };

  return quiz ? (
    <div className={'quiz-cover'}>
      {quiz.image && (
        <div className={'image-container'}>
          <img src={`/api/files/${quiz.image}`} alt={quiz.title} />
          <div className="overlay" />
        </div>
      )}
      <div className={'quiz-detail'}>
        <Row gutter={[0, 12]} justify={'space-between'}>
          <Col>
            <Col>
              <Space>
                <Icon name={'subject-area'} className={'subject-area-icon'} />
                <div className={'subject-area-text text-xl font-bold'}>{quiz.subjectArea?.targetName}</div>
              </Space>
            </Col>
            <Col>
              <Space>
                <div className={'quiz-title font-bold'}>{quiz.title}</div>
                <Tag color={SECONDARY_COLOR} data-testid={'score-maximum-tag'} className={'info-tag font-bold'}>{`${calculateQuizMaxPoints(
                  quiz.questions
                )} ${t('quiz.points-abbr')}`}</Tag>
                {quiz.creator?.id === account?.id && quiz.published && (
                  <Tag className={'info-tag'} color={PRIMARY_COLOR}>
                    {t('global.published')}
                  </Tag>
                )}
              </Space>
            </Col>
          </Col>
          <Col>
            {isAllowedToModify && (
              <>
                <Button onClick={() => setSaveQuizModalVisible(true)}>{t('quiz.update.changeGeneralInformation')}</Button>
                <SaveQuizModal
                  visible={saveQuizModalVisible}
                  onClose={() => setSaveQuizModalVisible(false)}
                  onUpdate={() => updateQuiz()}
                />
              </>
            )}
          </Col>
          <Col xs={24}>{subjects()}</Col>
          <Col>
            <Text className={'quiz-description text-sm font-medium'}>{quiz.description}</Text>
          </Col>
        </Row>
        <Divider className={'divider'} />
      </div>
    </div>
  ) : null;
};

export default QuizDetail;
