import React, { FC } from 'react';
import { IconInterface } from './index';

const DragIcon: FC<IconInterface> = ({
  name,
  style = {},
  width = '27px',
  height = '14px',
  viewBox = '0 0 27 14',
  className = '',
  fill = 'none',
  stroke = '#8C8C8C',
}) => (
  <svg
    aria-label={name}
    className={className}
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6667 2.33329V2.33996M10.6667 6.99996V7.00663M10.6667 11.6666V11.6733M10.6667 2.99996C10.4899 2.99996 10.3203 2.92972 10.1953 2.8047C10.0702 2.67967 10 2.5101 10 2.33329C10 2.15648 10.0702 1.98691 10.1953 1.86189C10.3203 1.73686 10.4899 1.66663 10.6667 1.66663C10.8435 1.66663 11.013 1.73686 11.1381 1.86189C11.2631 1.98691 11.3333 2.15648 11.3333 2.33329C11.3333 2.5101 11.2631 2.67967 11.1381 2.8047C11.013 2.92972 10.8435 2.99996 10.6667 2.99996ZM10.6667 7.66663C10.4899 7.66663 10.3203 7.59639 10.1953 7.47136C10.0702 7.34634 10 7.17677 10 6.99996C10 6.82315 10.0702 6.65358 10.1953 6.52855C10.3203 6.40353 10.4899 6.33329 10.6667 6.33329C10.8435 6.33329 11.013 6.40353 11.1381 6.52855C11.2631 6.65358 11.3333 6.82315 11.3333 6.99996C11.3333 7.17677 11.2631 7.34634 11.1381 7.47136C11.013 7.59639 10.8435 7.66663 10.6667 7.66663ZM10.6667 12.3333C10.4899 12.3333 10.3203 12.2631 10.1953 12.138C10.0702 12.013 10 11.8434 10 11.6666C10 11.4898 10.0702 11.3202 10.1953 11.1952C10.3203 11.0702 10.4899 11 10.6667 11C10.8435 11 11.013 11.0702 11.1381 11.1952C11.2631 11.3202 11.3333 11.4898 11.3333 11.6666C11.3333 11.8434 11.2631 12.013 11.1381 12.138C11.013 12.2631 10.8435 12.3333 10.6667 12.3333Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 2.33329V2.33996M16 6.99996V7.00663M16 11.6666V11.6733M16 2.99996C15.8232 2.99996 15.6536 2.92972 15.5286 2.8047C15.4036 2.67967 15.3333 2.5101 15.3333 2.33329C15.3333 2.15648 15.4036 1.98691 15.5286 1.86189C15.6536 1.73686 15.8232 1.66663 16 1.66663C16.1768 1.66663 16.3464 1.73686 16.4714 1.86189C16.5964 1.98691 16.6667 2.15648 16.6667 2.33329C16.6667 2.5101 16.5964 2.67967 16.4714 2.8047C16.3464 2.92972 16.1768 2.99996 16 2.99996ZM16 7.66663C15.8232 7.66663 15.6536 7.59639 15.5286 7.47136C15.4036 7.34634 15.3333 7.17677 15.3333 6.99996C15.3333 6.82315 15.4036 6.65358 15.5286 6.52855C15.6536 6.40353 15.8232 6.33329 16 6.33329C16.1768 6.33329 16.3464 6.40353 16.4714 6.52855C16.5964 6.65358 16.6667 6.82315 16.6667 6.99996C16.6667 7.17677 16.5964 7.34634 16.4714 7.47136C16.3464 7.59639 16.1768 7.66663 16 7.66663ZM16 12.3333C15.8232 12.3333 15.6536 12.2631 15.5286 12.138C15.4036 12.013 15.3333 11.8434 15.3333 11.6666C15.3333 11.4898 15.4036 11.3202 15.5286 11.1952C15.6536 11.0702 15.8232 11 16 11C16.1768 11 16.3464 11.0702 16.4714 11.1952C16.5964 11.3202 16.6667 11.4898 16.6667 11.6666C16.6667 11.8434 16.5964 12.013 16.4714 12.138C16.3464 12.2631 16.1768 12.3333 16 12.3333Z"
      stroke="#8C8C8C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DragIcon;
