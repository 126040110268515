import React, { FC } from 'react';
import './App.less';
import './global.scss';
import Routes from './Routes';
import { Layout } from 'antd';
import setupAxiosInterceptors from './config/axios-interceptor';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const App: FC = () => {
  library.add(faCheckCircle, faTimesCircle);

  return (
    <div className="App">
      <Layout>
        <Routes />
      </Layout>
    </div>
  );
};

export default App;

setupAxiosInterceptors();
