import React, { FC } from 'react';
import { IconInterface } from './index';

const UserIcon: FC<IconInterface> = ({
  name,
  style = {},
  width = '4px',
  height = '16px',
  viewBox = '0 0 4 16',
  className = '',
  fill = 'none',
  stroke = '#8C8C8C',
}) => (
  <svg
    aria-label={name}
    className={className}
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.99999 2.16768V2.17602M1.99999 8.00102V8.00935M1.99999 13.8343V13.8427M1.99999 3.00102C1.77898 3.00102 1.56701 2.91322 1.41073 2.75694C1.25445 2.60066 1.16666 2.3887 1.16666 2.16768C1.16666 1.94667 1.25445 1.73471 1.41073 1.57843C1.56701 1.42215 1.77898 1.33435 1.99999 1.33435C2.221 1.33435 2.43297 1.42215 2.58925 1.57843C2.74553 1.73471 2.83332 1.94667 2.83332 2.16768C2.83332 2.3887 2.74553 2.60066 2.58925 2.75694C2.43297 2.91322 2.221 3.00102 1.99999 3.00102ZM1.99999 8.83435C1.77898 8.83435 1.56701 8.74655 1.41073 8.59027C1.25445 8.43399 1.16666 8.22203 1.16666 8.00102C1.16666 7.78 1.25445 7.56804 1.41073 7.41176C1.56701 7.25548 1.77898 7.16768 1.99999 7.16768C2.221 7.16768 2.43297 7.25548 2.58925 7.41176C2.74553 7.56804 2.83332 7.78 2.83332 8.00102C2.83332 8.22203 2.74553 8.43399 2.58925 8.59027C2.43297 8.74655 2.221 8.83435 1.99999 8.83435ZM1.99999 14.6677C1.77898 14.6677 1.56701 14.5799 1.41073 14.4236C1.25445 14.2673 1.16666 14.0554 1.16666 13.8343C1.16666 13.6133 1.25445 13.4014 1.41073 13.2451C1.56701 13.0888 1.77898 13.001 1.99999 13.001C2.221 13.001 2.43297 13.0888 2.58925 13.2451C2.74553 13.4014 2.83332 13.6133 2.83332 13.8343C2.83332 14.0554 2.74553 14.2673 2.58925 14.4236C2.43297 14.5799 2.221 14.6677 1.99999 14.6677Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UserIcon;
