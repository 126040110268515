import React, { FC, ReactNode } from 'react';
import { FormListFieldData } from 'antd/es/form/FormList';
import ChoiceInputListFooter from './choice-input-list-footer';
import BaseTextInput from './base-text-input';
import { useTranslation } from 'react-i18next';

interface IProps {
  textFields: FormListFieldData[];
  add: () => void;
  remove: (index: number | number[]) => void;
  errors: ReactNode[];
}

const TextInputList: FC<IProps> = ({ textFields, add, remove, errors }) => {
  const { t } = useTranslation();

  return (
    <>
      {textFields.map(textField => (
          <>
            <p className={'choice-label'}>{t('quiz.update.questionForm.answer')}</p>
            <BaseTextInput key={textField.key} textField={textField} textFieldsLength={textFields.length} remove={remove} />
          </>
      ))}
      <ChoiceInputListFooter errors={errors} add={add} />
    </>
  );
};

export default TextInputList;
