import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { User } from '../../model/user.model';
import { getAccount } from '../../api/accountApi';

interface IProvider {
  children: React.ReactNode;
}

interface IContext {
  account: User | undefined;
  setAccount: Dispatch<SetStateAction<User | undefined>>;
}

const initialContext: IContext = {
  account: undefined,
  setAccount(): void {},
};

export const RootContext = React.createContext<IContext>(initialContext);

const RootProvider: FC<IProvider> = ({ children }: IProvider) => {
  const [account, setAccount] = useState<User | undefined>(undefined);

  useEffect(() => {
    getAccount()
      .then(resp => setAccount(resp.data))
      .catch(() => console.error('Could not fetch account'));
  }, []);

  return (
    <RootContext.Provider
      value={{
        account,
        setAccount,
      }}
    >
      {children}
    </RootContext.Provider>
  );
};

function useAccountState(): {
  account: User | undefined;
  setAccount: React.Dispatch<React.SetStateAction<User | undefined>>;
} {
  const context = React.useContext(RootContext);
  if (context === undefined) {
    throw new Error('useAccountState must be used within a RootContext');
  }
  return { account: context.account, setAccount: context.setAccount };
}

export { RootProvider, useAccountState };
