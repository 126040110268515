import React, { FC } from 'react';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

type PreviewButtonProps = {
  preview: boolean;
  setPreview: (preview: boolean) => void;
  className?: string;
};

const PreviewButton: FC<PreviewButtonProps> = ({ setPreview, preview, className = '' }): JSX.Element => {
  const props = {
    onClick: () => setPreview(!preview),
    className: `input-preview-btn ${className}`,
  };

  return preview ? <EyeInvisibleOutlined {...props} /> : <EyeOutlined {...props} />;
};

export default PreviewButton;
