import React, { FC } from 'react';
import { IconInterface } from './index';

const ListViewIcon: FC<IconInterface> = ({
  name,
  style = {},
  width = '18px',
  height = '14px',
  viewBox = '0 0 18 14',
  className = '',
  fill = 'none',
  stroke = 'black',
}) => (
  <svg
    aria-label={name}
    className={className}
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 13H17M1 1H17H1ZM1 5H17H1ZM1 9H17H1Z" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default ListViewIcon;
