import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Input, Row } from 'antd';
import { FormListFieldData } from 'antd/es/form/FormList';
import Icon from '../../../shared/icons';
import PreviewButton from '../../shared/preview-button';
import Preview from '../../shared/preview';
import MathJaxTutorialModal from '../../shared/math-jax-tutorial-modal';

interface IProps {
  textField: FormListFieldData;
  textFieldsLength: number;
  remove: (index: number | number[]) => void;
}

const BaseTextInput: FC<IProps> = ({ textField, textFieldsLength, remove }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string>();
  const [preview, setPreview] = useState<boolean>(false);
  const ref = useRef<any>();

  useEffect(() => {
    if (value === undefined) {
      setValue(ref?.current?.input?.value);
    }
  }, [ref?.current]);

  return (
    <Row align={'middle'} className={'text-row'}>
      <Col xs={22}>
        <Form.Item
          name={[textField.name, 'choiceString']}
          rules={[
            {
              required: true,
              message: `${t('quiz.update.questionForm.answerRequired')}`,
            },
          ]}
        >
          <Input
            data-testid={'question-textbox'}
            size={'large'}
            onChange={e => setValue(e.target.value)}
            placeholder={t('quiz.update.questionForm.answer')}
            className={'border-radius-0'}
            addonAfter={
              <div className={'field-addon'}>
                <PreviewButton setPreview={setPreview} preview={preview} />
                <MathJaxTutorialModal />
              </div>
            }
            ref={ref}
          />
        </Form.Item>
      </Col>
      <Col xs={1} offset={1}>
        <div className="remove-choice" onClick={() => textFieldsLength > 1 && remove(textField.name)}>
          <Icon name={'trash'} />
        </div>
      </Col>
      <Form.Item valuePropName={'checked'} name={[textField.name, 'correct']} initialValue={true} />
      <Preview preview={preview} value={value} />
    </Row>
  );
};

export default BaseTextInput;
