import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { Quiz } from '../../model/quiz.model';

interface IQuizEditProvider {
  children: React.ReactNode;
}

interface IContext {
  quiz: Quiz | undefined;
  setQuiz: Dispatch<SetStateAction<Quiz | undefined>>;
  questionCreationForm: FC;
  setQuestionCreationForm: Dispatch<SetStateAction<FC>>;
  selectedQuestionType: string;
  setSelectedQuestionType: Dispatch<SetStateAction<string>>;
}

const initialContext: IContext = {
  quiz: undefined,
  setQuiz(): void {},
  questionCreationForm: () => null,
  setQuestionCreationForm: () => null,
  selectedQuestionType: '',
  setSelectedQuestionType: () => '',
};

export const QuizEditContext = React.createContext<IContext>(initialContext);

const QuizEditProvider: FC<IQuizEditProvider> = ({ children }) => {
  const [quiz, setQuiz] = useState<Quiz | undefined>(undefined);
  const [questionCreationForm, setQuestionCreationForm] = useState<FC>(() => null);
  const [selectedQuestionType, setSelectedQuestionType] = useState<string>('');

  return (
    <QuizEditContext.Provider
      value={{
        quiz,
        setQuiz,
        questionCreationForm,
        setQuestionCreationForm,
        selectedQuestionType,
        setSelectedQuestionType,
      }}
    >
      {children}
    </QuizEditContext.Provider>
  );
};

function useQuizState(): {
  quiz: Quiz | undefined;
  setQuiz: React.Dispatch<React.SetStateAction<Quiz | undefined>>;
} {
  const context = React.useContext(QuizEditContext);
  if (context === undefined) {
    throw new Error('useQuizState must be used within a QuizEditContext');
  }
  return { quiz: context.quiz, setQuiz: context.setQuiz };
}

function useQuestionCreationFormState(): {
  questionCreationForm: FC;
  setQuestionCreationForm: Dispatch<SetStateAction<FC>>;
} {
  const context = React.useContext(QuizEditContext);
  if (context === undefined) {
    throw new Error('useQuestionCreationFormState must be used within a QuizEditContext');
  }
  return { questionCreationForm: context.questionCreationForm, setQuestionCreationForm: context.setQuestionCreationForm };
}

function useSelectedQuestionTypeState(): {
  selectedQuestionType: string;
  setSelectedQuestionType: Dispatch<SetStateAction<string>>;
} {
  const context = React.useContext(QuizEditContext);
  if (context === undefined) {
    throw new Error('useSelectedQuestionTypeState must be used within a QuizEditContext');
  }
  return { selectedQuestionType: context.selectedQuestionType, setSelectedQuestionType: context.setSelectedQuestionType };
}

export { QuizEditProvider, useQuizState, useQuestionCreationFormState, useSelectedQuestionTypeState };
