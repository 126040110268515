import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { Button, Popconfirm } from 'antd';
import { Quiz } from '../../model/quiz.model';
import { useTranslation } from 'react-i18next';
import { getDeepLinkingResponseParams, IDeepLinkingResponseParams } from '../../api/ltiApi';

interface ISelectQuizButton {
  quiz: Quiz;
}

const SelectQuizButton: FC<ISelectQuizButton> = ({ quiz }) => {
  const { t } = useTranslation();
  const [responseParams, setResponseParams] = useState<IDeepLinkingResponseParams>();
  const formRef = useRef<HTMLFormElement>(null);
  const submitForm = () => formRef?.current?.submit();

  useEffect(() => {
    getDeepLinkingResponseParams(quiz.id as number)
      .then(setResponseParams)
      .catch(() => console.error('Could not fetch deep linking result params'));
  }, []);

  return (
    <>
      <Popconfirm
        title={t('quiz.preview.selectQuiz.areYouSure')}
        okText={t('global.actions.yes')}
        cancelText={t('global.actions.no')}
        onConfirm={submitForm}
      >
        <Button type="primary">{t('quiz.preview.selectQuiz.button')}</Button>
      </Popconfirm>
      {responseParams && (
        <form action={responseParams.url} method={'post'} ref={formRef}>
          <input name={'JWT'} type={'hidden'} value={responseParams.jwt} />
        </form>
      )}
    </>
  );
};

export default memo(SelectQuizButton);
