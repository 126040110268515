import { Button, Upload, UploadProps } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { UploadedFile } from '../../model/uploaded-file.model';
import { useTranslation } from 'react-i18next';
import { errorMessage } from '../../shared/util/message-utils';
import Icon from '../../shared/icons';

interface ImageUploadProps {
  uniqueImageName?: string;
  setUniqueImageName: Dispatch<SetStateAction<string | undefined>>;
  type?: 'button' | 'dragdrop';
}

const acceptedImages = [
  'image/jpg',
  'image/jpeg',
  'image/jfif',
  'image/pjpeg',
  'image/pjp',
  'image/png',
  'image/bmp',
  'image/webp',
  'image/avif',
  'image/gif',
];

class CoverImage implements UploadFile {
  name: string;
  uid: string;
  thumbUrl: string;
  size: number;
  type: string;

  constructor(uniqueImageName: string) {
    this.name = uniqueImageName.split('.').slice(-2).join('.');
    this.uid = uniqueImageName;
    this.thumbUrl = `/api/files/${uniqueImageName}`;
    this.size = 0;
    this.type = `image/${uniqueImageName.split('.').slice(-1)[0]}`;
  }
}

const ImageUpload: FC<ImageUploadProps> = ({ uniqueImageName, setUniqueImageName, type = 'button' }) => {
  const { t } = useTranslation();
  const coverImageExists = !!uniqueImageName;
  const [imageValid, setImageValid] = useState<boolean>(true);
  const [imageList, setImageList] = useState<UploadFile<UploadedFile>[]>([]);

  useEffect(() => {
    !!uniqueImageName && setImageList([new CoverImage(uniqueImageName)]);
  }, []);

  const uploadProps: UploadProps = {
    action: '/api/files',
    multiple: false,
    maxCount: 1,
    accept: acceptedImages.join(','),
    listType: 'picture',
    onPreview() {
      return;
    },
    beforeUpload(file: RcFile) {
      const isLt2M = file.size / 1048576 <= 2;
      if (!isLt2M) {
        setImageValid(false);
        errorMessage(t('quiz.modal.imageSizeExceeds2Mb'));
      } else {
        setImageValid(true);
      }
      return isLt2M;
    },
    onChange(info: UploadChangeParam<UploadFile<UploadedFile>>) {
      imageValid && setImageList([...info.fileList]);

      if (info.file.status === 'done') {
        setUniqueImageName(info.file.response?.uniqueName);
      }
    },
    onRemove() {
      setUniqueImageName(undefined);
    },
  };

  return type === 'button' ? (
    <Upload {...uploadProps} fileList={imageList}>
      <Button icon={<UploadOutlined />}>{!coverImageExists ? t('quiz.modal.chooseCoverImage') : t('quiz.modal.replaceCoverImage')}</Button>
    </Upload>
  ) : (
    <Upload.Dragger {...uploadProps} fileList={imageList}>
      <p className="ant-upload-drag-icon mb-0">
        <Icon name={'plus'} />
      </p>
      <p className="ant-upload-hint">{!coverImageExists ? t('quiz.update.questionForm.upload') : t('quiz.update.questionForm.replace')}</p>
    </Upload.Dragger>
  );
};

export default ImageUpload;
