import React, { FC, useEffect } from 'react';
import { Button, Col, Layout, message, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import './quiz-update.scss';
import { useParams } from 'react-router-dom';
import { getQuiz, usePublishQuiz } from '../../api/quizApi';
import QuestionSaveForm from './question-save-form/question-save-form';
import QuestionBox from './question-box';
import { useQuestionCreationFormState, useQuizState, useSelectedQuestionTypeState } from '../../shared/context/quiz-edit-context';
import { MULTIPLE_CHOICE_CHECKBOX, MULTIPLE_CHOICE_RADIO, SHORT_TEXT } from '../../config/constants';
import QuizDetail from '../shared/quiz-detail';
import Header from '../../shared/layout/header/header';
import Icon from '../../shared/icons';
import { convertServerTimeToShortDate } from '../../shared/util/date-utils';
import { getQuestionTypeDescriptionKey, getQuestionTypeKey } from '../../shared/util/quiz-util';
import { errorMessage, successMessage } from '../../shared/util/message-utils';
import { PUBLISH, UNPUBLISH } from '../../shared/constants';

const QuizUpdate: FC = () => {
  const { Content } = Layout;
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { quiz, setQuiz } = useQuizState();
  const { questionCreationForm, setQuestionCreationForm } = useQuestionCreationFormState();
  const { selectedQuestionType, setSelectedQuestionType } = useSelectedQuestionTypeState();
  const publishQuiz = usePublishQuiz(id);

  useEffect(() => {
    getQuiz(id)
      .then(res => setQuiz(res))
      .catch(() => console.error('Could not fetch a quiz'));
  }, []);

  useEffect(() => {
    selectedQuestionType &&
      setQuestionCreationForm(() => (
        <QuestionSaveForm questionType={selectedQuestionType} sequenceNumber={(quiz?.questions?.length || 0) + 1} />
      ));
  }, [selectedQuestionType]);

  useEffect(() => {
    if (publishQuiz?.isError) {
      console.error('Could not publish quiz', publishQuiz.error);
      errorMessage(t(publishQuiz.variables === PUBLISH ? 'quiz.update.publishErrorMessage' : 'quiz.update.unpublishErrorMessage'));
    } else if (publishQuiz?.isSuccess) {
      successMessage(t(publishQuiz.variables === PUBLISH ? 'quiz.update.publishSuccessMessage' : 'quiz.update.unpublishSuccessMessage'));
      setQuiz(publishQuiz.data.data);
    }
  }, [publishQuiz?.isSuccess, publishQuiz?.isError]);

  const closeQuestionSaveForm = () => {
    setQuestionCreationForm(() => null);
  };

  const openNewQuestionSaveForm = (questionType: string) => {
    closeQuestionSaveForm();
    setSelectedQuestionType(questionType);
  };

  const HeaderRightComponent = () => {
    return (
      <Space size={10} wrap>
        <span className={'font-medium last-saved'}>{`${t('quiz.update.lastSaved')}: ${
          quiz?.createdDate ? convertServerTimeToShortDate(quiz.createdDate) : ''
        }`}</span>
        {/* Not implemented yet */}
        {/* <Button className={'preview-button'}>{t('global.quizCard.preview')}</Button> */}
        <Button onClick={() => message.success(t('quiz.update.saveSuccessMessage'))}>{t('quiz.update.questionForm.save')}</Button>
        <Button onClick={() => publishQuiz.mutateAsync(quiz?.published ? UNPUBLISH : PUBLISH)} className={'font-semibold'} type={'primary'}>
          {t(quiz?.published ? 'quiz.update.unpublish' : 'quiz.update.publish')}
        </Button>
      </Space>
    );
  };

  const QuestionAdd = ({
    questionType,
    questionDescription,
    onQuestionAddClick,
  }: {
    questionType: string;
    questionDescription: string;
    onQuestionAddClick: () => void;
  }) => {
    return (
      <div className={'question-add'}>
        {/* Not implemented yet */}
        {/* <Icon name={'drag'} className={'drag-icon'} /> */}
        <div className={'multiple-choice-checkbox-icon-container'}>
          <Icon className={'multiple-choice-checkbox-icon'} name={'multiple-choice-checkbox'} />
        </div>
        <Space size={0} direction={'vertical'} className={'question-text'}>
          <div className={'question-type text-sm font-semibold'}>{t(getQuestionTypeKey(questionType))}</div>
          <div className={'question-description text-sm font-normal'}>{questionDescription}</div>
        </Space>
        {selectedQuestionType !== questionType && (
          <Button className={'add-button'} onClick={onQuestionAddClick}>
            {t('quiz.update.add')}
          </Button>
        )}
      </div>
    );
  };

  const calculateQuestionsCountText = () => {
    const totalQuestions = quiz?.questions?.length || 0;
    if (totalQuestions === 1) {
      return `${totalQuestions} ${t('quiz.detail.question')}`;
    }
    return `${totalQuestions} ${t('quiz.detail.questions')}`;
  };

  return quiz ? (
    <Layout className={'quiz-update'}>
      <Header rightComponent={<HeaderRightComponent />} />
      <Content className={'content'}>
        <Row className={'content-row'}>
          <Col xl={{ span: 16, order: 1 }} xs={{ span: 24, order: 1 }} className={'quiz-data-col'}>
            <QuizDetail quiz={quiz} onQuizChange={setQuiz} isAllowedToModify />
          </Col>
          <Col xl={{ span: 16, order: 3 }} xs={{ span: 24, order: 3 }} className={'questions-col'}>
            <div className={'questions-count-text font-bold text-xl'}>{calculateQuestionsCountText()}</div>
            {quiz.questions?.map((question, i) => (
              <QuestionBox key={i} question={question} questionType={question.questionType} sequenceNumber={i + 1} editable />
            ))}
            {questionCreationForm}
          </Col>
          <Col xl={{ span: 8, order: 2 }} xs={{ span: 24, order: 2 }} className={'question-creation-col'}>
            <div className={'question-creation-container'}>
              <div className={'add-question-text font-bold text-xl'}>{t('quiz.update.addQuestion')}</div>
              <div className={'add-question-instruction-text text-sm font-normal'}>{t('quiz.update.questionAddingInstruction')}</div>
              <Space direction={'vertical'} className={'question-add-container'}>
                <QuestionAdd
                  questionType={MULTIPLE_CHOICE_CHECKBOX}
                  questionDescription={t(getQuestionTypeDescriptionKey(MULTIPLE_CHOICE_CHECKBOX))}
                  onQuestionAddClick={() => openNewQuestionSaveForm(MULTIPLE_CHOICE_CHECKBOX)}
                />
                <QuestionAdd
                  questionType={MULTIPLE_CHOICE_RADIO}
                  questionDescription={t(getQuestionTypeDescriptionKey(MULTIPLE_CHOICE_RADIO))}
                  onQuestionAddClick={() => openNewQuestionSaveForm(MULTIPLE_CHOICE_RADIO)}
                />
                <QuestionAdd
                  questionType={SHORT_TEXT}
                  questionDescription={t(getQuestionTypeDescriptionKey(SHORT_TEXT))}
                  onQuestionAddClick={() => openNewQuestionSaveForm(SHORT_TEXT)}
                />
                {/* Not implemented yet */}
                {/* <QuestionAdd */}
                {/*  questionType={LONG_TEXT} */}
                {/*  questionDescription={t(getQuestionTypeDescriptionKey(''))} */}
                {/*  onQuestionAddClick={() => {}} */}
                {/* />*/}
                {/* <QuestionAdd */}
                {/*  questionType={LONG_TEXT_FILE_UPLOAD} */}
                {/*  questionDescription={t(getQuestionTypeDescriptionKey(''))} */}
                {/*  onQuestionAddClick={() => {}} */}
                {/* /> */}
                {/* <QuestionAdd */}
                {/*  questionType={FILL_IN_THE_GAP} */}
                {/*  questionDescription={t(getQuestionTypeDescriptionKey(''))} */}
                {/*  onQuestionAddClick={() => {}} */}
                {/* /> */}
              </Space>
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  ) : null;
};

export default QuizUpdate;
