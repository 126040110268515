import React, { FC } from 'react';
import { IconInterface } from './index';

const TrashIcon: FC<IconInterface> = ({
  style = {},
  width = '20px',
  height = '20px',
  viewBox = '0 0 20 20',
  className = '',
  fill = '#6B33F9',
  stroke = 'none',
  ariaLabel = '',
}) => (
  <svg
    aria-label={ariaLabel}
    className={className}
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.7168 16.4062H14.2832L14.7559 6.40625H5.24414L5.7168 16.4062Z" fill="#F7F0FF" />
    <path
      d="M16.875 5H14.375V3.4375C14.375 2.74805 13.8145 2.1875 13.125 2.1875H6.875C6.18555 2.1875 5.625 2.74805 5.625 3.4375V5H3.125C2.7793 5 2.5 5.2793 2.5 5.625V6.25C2.5 6.33594 2.57031 6.40625 2.65625 6.40625H3.83594L4.31836 16.6211C4.34961 17.2871 4.90039 17.8125 5.56641 17.8125H14.4336C15.1016 17.8125 15.6504 17.2891 15.6816 16.6211L16.1641 6.40625H17.3438C17.4297 6.40625 17.5 6.33594 17.5 6.25V5.625C17.5 5.2793 17.2207 5 16.875 5ZM7.03125 3.59375H12.9688V5H7.03125V3.59375ZM14.2832 16.4062H5.7168L5.24414 6.40625H14.7559L14.2832 16.4062Z"
      fill={fill}
    />
  </svg>
);

export default TrashIcon;
