import axios from 'axios';
import config from '../config';
import { EducationalAlignment } from '../model/educational-alignment.model';

export type AlignmentType = 'subjectArea' | 'subject';
export const SUBJECT_AREA = 'subjectArea';
export const SUBJECT = 'subject';

interface EducationalAlignmentFilter {
  alignmentType?: AlignmentType;
  title?: string;
  sort?: string;
  page?: number;
  size?: number;
}

export const getAllEducationalAlignments = (params: EducationalAlignmentFilter): Promise<EducationalAlignment[]> => {
  return axios
    .get<EducationalAlignment[]>(`${config.SCHOOLABY_URL}/api/educational-alignments`, { params })
    .then(r => {
      return r.data;
    });
};
