import React, { FC, ReactNode, RefObject } from 'react';
import { FormListFieldData } from 'antd/es/form/FormList';
import { Form, FormInstance, Radio } from 'antd';
import BaseChoiceInput from './base-choice-input';
import ChoiceInputListFooter from './choice-input-list-footer';
import { Choice } from '../../../model/choice.model';
import cloneDeep from 'lodash/cloneDeep';
import { useTranslation } from 'react-i18next';

interface IProps {
  choiceFields: FormListFieldData[];
  add: () => void;
  remove: (index: number | number[]) => void;
  errors: ReactNode[];
  formRef: RefObject<FormInstance>;
  form: FormInstance;
}

const RadioInputList: FC<IProps> = ({ choiceFields, add, remove, errors, formRef, form }) => {
  const { t } = useTranslation();
  const handleSetAsDefaultChange = (index: number) => {
    const choices: Choice[] = formRef?.current?.getFieldValue('choices') as Choice[];
    const clonedChoices = cloneDeep(choices).map((choice: Choice, choiceIndex: number) => {
      choice.correct = choiceIndex === index;
      return choice;
    });
    form.setFieldsValue({ choices: clonedChoices });
  };

  const renderChoiceRadio = (choiceFieldName: number, i: number) => (
    <Form.Item valuePropName={'checked'} name={[choiceFieldName, 'correct']}>
      <Radio data-testid={'choice-radio'} value={choiceFieldName} onChange={() => handleSetAsDefaultChange(i)}>
        {t('quiz.update.questionForm.correct')}
      </Radio>
    </Form.Item>
  );

  return (
    <>
      {choiceFields.map((choiceField, i) => (
        <BaseChoiceInput
          key={choiceField.key}
          choiceField={choiceField}
          choiceFieldsLength={choiceFields.length}
          remove={remove}
          choiceRightSide={renderChoiceRadio(choiceField.name, i)}
          index={i}
        />
      ))}
      <ChoiceInputListFooter errors={errors} add={add} />
    </>
  );
};

export default RadioInputList;
