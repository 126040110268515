import { Quiz } from '../../model/quiz.model';
import { Question } from '../../model/question.model';
import { Answer } from '../../model/answer.model';
import {
  FILL_IN_THE_GAP,
  LONG_TEXT,
  LONG_TEXT_FILE_UPLOAD,
  MULTIPLE_CHOICE_CHECKBOX,
  MULTIPLE_CHOICE_RADIO,
  SHORT_TEXT,
} from '../../config/constants';
import { CORRECT, INCORRECT, PARTIALLY_CORRECT } from '../constants';

export const getAuthorFullName = (quiz: Quiz): string | undefined => {
  const givenName = quiz.creator?.givenName;
  const familyName = quiz.creator?.familyName;
  if (givenName && familyName) {
    return `${givenName} ${familyName}`;
  }
};

export const calculateQuizMaxPoints = (questions: Question[] | undefined): number => {
  let maxPoints = 0;
  questions?.forEach(question => (maxPoints += question.points));
  return maxPoints;
};

export const getAnswerColorClass = (answer?: Answer, question?: Question): typeof CORRECT | typeof PARTIALLY_CORRECT | typeof INCORRECT => {
  if ((!!answer?.textAnswer || !!answer?.choices?.length) && answer?.score !== undefined && question?.points !== undefined) {
    if (answer.score === question?.points) {
      return CORRECT;
    } else if (answer?.score > 0) {
      return PARTIALLY_CORRECT;
    }
  }

  return INCORRECT;
};

export const isTextBasedQuestion = (question: Question): boolean =>
  question?.questionType === SHORT_TEXT || question?.questionType === LONG_TEXT;

export const textAnswerCorrect = (textAnswer: string, question: Question): boolean =>
  question.choices?.some(c => c.choiceString === textAnswer && c.correct) || false;

export const getQuestionTypeKey = (questionType: string): string => {
  switch (questionType) {
    case MULTIPLE_CHOICE_CHECKBOX:
      return 'quiz.update.questionType.multipleChoiceCheckbox';
    case MULTIPLE_CHOICE_RADIO:
      return 'quiz.update.questionType.multipleChoiceRadio';
    case LONG_TEXT:
      return 'quiz.update.questionType.longTextEntryAnswer';
    case SHORT_TEXT:
      return 'quiz.update.questionType.shortTextEntryAnswer';
    case LONG_TEXT_FILE_UPLOAD:
      return 'quiz.update.questionType.longTextEntryAnswerWithFileUpload';
    case FILL_IN_THE_GAP:
      return 'quiz.update.questionType.fillInTheGaps';

    default:
      return '';
  }
};

export const getQuestionTypeDescriptionKey = (questionType: string): string => {
  switch (questionType) {
    case MULTIPLE_CHOICE_CHECKBOX:
      return 'quiz.update.questionDescription.multipleCorrectAnswers';
    case MULTIPLE_CHOICE_RADIO:
      return 'quiz.update.questionDescription.oneCorrectAnswers';

    default:
      return 'quiz.update.questionDescription.multipleCorrectAnswers';
  }
};
