import React, { FC, ReactNode } from 'react';
import { FormListFieldData } from 'antd/es/form/FormList';
import { Checkbox, Form } from 'antd';
import BaseChoiceInput from './base-choice-input';
import ChoiceInputListFooter from './choice-input-list-footer';
import { useTranslation } from 'react-i18next';

interface IProps {
  choiceFields: FormListFieldData[];
  add: () => void;
  remove: (index: number | number[]) => void;
  errors: ReactNode[];
}

interface IChoiceCheckboxProps {
  choiceFieldName: number;
}

const CheckboxInputList: FC<IProps> = ({ choiceFields, add, remove, errors }) => {
  const { t } = useTranslation();
  const ChoiceCheckbox: FC<IChoiceCheckboxProps> = ({ choiceFieldName }) => (
    <Form.Item valuePropName={'checked'} name={[choiceFieldName, 'correct']}>
      <Checkbox data-testid={'choice-checkbox'}>{t('quiz.update.questionForm.correct')}</Checkbox>
    </Form.Item>
  );

  return (
    <>
      {choiceFields.map((choiceField, index) => (
        <BaseChoiceInput
          key={choiceField.key}
          choiceField={choiceField}
          choiceFieldsLength={choiceFields.length}
          remove={remove}
          choiceRightSide={<ChoiceCheckbox choiceFieldName={choiceField.name} />}
          index={index}
        />
      ))}
      <ChoiceInputListFooter errors={errors} add={add} />
    </>
  );
};

export default CheckboxInputList;
